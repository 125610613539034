import { Reducer } from "redux";

export const SHOW_UPGRADE_MODAL = 'SHOW_UPGRADE_MODAL';
export const HIDE_UPGRADE_MODAL = 'HIDE_UPGRADE_MODAL';

export const showUpgradeModal = () => {
    return {
        type: SHOW_UPGRADE_MODAL,
        open: true,
    };
};

export const hideUpgradeModal = () => {
    return {
        type: HIDE_UPGRADE_MODAL,
        open: false
    }
};

export const initialState = {
    open: false,
};

export const upgradeReducer: Reducer<typeof initialState> = (
    state = initialState,
    action: any
) => {
    switch (action.type) {
        case SHOW_UPGRADE_MODAL:
            return {
                ...state,
                open: true
            };
        case HIDE_UPGRADE_MODAL:
            return {
                ...state,
                open: false

            };
        default:
            return state;
    }
};