import moment from "moment";

type DataObject = {
  data: any[];
  pastPeriod: any[];
  [key: string]: any; // Define an index signature to allow string indexing
};

export const convertDataToStats = (
  data: DataObject,
  statsBy: string = "data"
) => {
  if (!data[statsBy]) {
    return [];
  }
  let { metricsBreakdown } = data?.[statsBy]?.[0] || {};
  if (!metricsBreakdown?.length) {
    return [];
  }
  const stats: any[] = [];
  metricsBreakdown.forEach((x: any) => {
    const day = {
      start: moment(x.date).startOf("day").format(),
      end: moment(x.date).endOf("day").format(),
      id: moment(x.date).format("ll"),
      hours: { 0: x.metrics },
    };
    stats.push(day);
  });
  return stats;
};
