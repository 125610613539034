import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import FacebookIconBase64 from "../icons/FacebookIconBase64";
import GoogleAdsIconBase64 from "../icons/GoogleIconBase64";
import TiktokIconBase64 from "../icons/TiktokIconBase64";
import TripleWhaleIconBase64 from "../icons/TripleWhaleIconBase64";
import INDUSTRIES from "./industries";

export const AOV_SEGMENTS: OptionDescriptor[] = [
   {
    value: ">$100",
    label: ">$100",
  },{
    value: "$100+",
    label: "$100+",
  },
  {
    value: 'unknown',
    label: 'unknown',
  }
 
];

export const TOTAL_REVENUE_SEGMENTS: OptionDescriptor[] = [
  {
    value: "<$1M",
    label: "<$1M",
  },
  {
    value: "$1M-$10M",
    label: "$1M-$10M",
  },
  {
    value: "$10M+",
    label: "$10M+",
  },
  {
    value: 'unknown',
    label: 'unknown',
  }
];

export const Services = {
  "facebook-ads": {
    name: "Meta",
    icon: ({ small }: { small: boolean }) => FacebookIconBase64({ small }),
  },
  "google-ads": {
    name: "Google",
    icon: ({ small }: { small: boolean }) => GoogleAdsIconBase64({ small }),
  },
  "tiktok-ads": {
    name: "Tiktok",
    icon: ({ small }: { small: boolean }) => TiktokIconBase64({ small }),
  },
  "triple-whale": {
    name: "TW",
    icon: TripleWhaleIconBase64,
  },
};

export const ICONS_NAMES = {
  "facebook-ads": "facebook-circle",
  "google-ads": "google",
  "tiktok-ads": "tiktok",
  "triple-whale": "triple-whale-logo",
};

export const FILTERS = [
  {
    list: AOV_SEGMENTS.map((item) => item.value),
    id: "aov",
    field: "selectedAOV",
  },
  {
    list: TOTAL_REVENUE_SEGMENTS.map((item) => item.value),
    id: "revenue",
    field: "selectedRevenue",
  },
  {
    list: Object.keys(INDUSTRIES),
    id: "industry",
    field: "selectedCategory",
  },
];
