import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

// hooks
//import { useConnectedAccounts } from '../hooks/useConnectedAccounts';

// types
//import type { RootState } from 'reducers/RootType';
import { FacebookAccountObject, ServicesId } from "../../types/types";

// style
import module_styles from "./Integrations.module.scss";

// components
import { Modal, Spinner } from "@shopify/polaris";
import { useAppSelector, RootState } from "../..";

interface IConnectionDetailsModal {
  open: boolean;
  platformName: string;
  onClose: (...args: any[]) => any;
  connected?: boolean;
  serviceId: ServicesId;
  configHook?: () => Promise<any> | void;
}

export const ConnectionDetailsModal: React.FC<IConnectionDetailsModal> = ({
  connected,
  open,
  platformName,
  onClose,
  serviceId,
  configHook,
}) => {
  // STATES
  const [loading, setLoading] = useState<boolean>(false);
  const { googleAds, facebookAds, tiktokAds, connectedStatus } = useAppSelector(
    (state: RootState) => state.accounts
  );
  let adAccounts = { manager: "", descriptive_name: "" };
  if (serviceId === "google-ads") {
    adAccounts = Object.values(googleAds)?.[0] as {
      manager: string;
      descriptive_name: string;
    };
  }
  else if (serviceId === "tiktok-ads") {
    const tiktokAccount = Object.values(tiktokAds)?.[0] as { id: string };
    adAccounts = { manager: tiktokAccount?.id, descriptive_name: "" };
  } else {
    const facebookAdsAccount = Object.values(
      facebookAds
    )?.[0] as FacebookAccountObject;
    adAccounts = {
      manager: facebookAdsAccount?.account_id,
      descriptive_name: facebookAdsAccount?.name,
    };
  }
  // COMPUTED
  const value: any = !adAccounts
    ? adAccounts
    : Array.isArray(adAccounts) ? 
      adAccounts
    : Object.values(adAccounts)[0];
  const configBtnClasses: string = [
    module_styles["integrations-btn"],
    module_styles[!connected ? "primary" : "secondary"],
  ].join(" ");

  // CALLBACKS
  const showConfig = useCallback(async () => {
    if (!configHook) return;

    try {
      setLoading(true);

      const res = configHook();
      if (res instanceof Promise) await res;
      onClose();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [loading, configHook]);

  // VIEW
  return (
    <Modal
      open={open}
      title={`Connection Details: ${platformName}`}
      onClose={onClose}
    >
      <div className="p-6">
        {!value ? (
           <div className="mx-8 my-5">{connectedStatus[serviceId]?.msg ||  'No account information available for this service.'}</div>
        ) : (
          <>
            <div key={`{${serviceId}_details_account`} className="px-2">
              <b>Account Id: </b> {adAccounts.manager}
            </div>
            {adAccounts.descriptive_name && (
              <div key={`{${serviceId}_details_name`} className="px-2">
                <b>Name: </b> {adAccounts.descriptive_name}
              </div>
            )}
          </>
        )}
        {connected && configHook && value && (
          <div className="text-right">
            {loading ? (
              <Spinner size="small" />
            ) : (
              <button onClick={showConfig} className={configBtnClasses}>
                Manage
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

// CONSTANTS
const borderClasses: string =
  "border-solid border-x-0 border-t-0 border-b border-slate-200";
const fieldsPerService: { [key in ServicesId]: any[] } = {
  "facebook-ads": [
    { field: "account_id", label: "Account Id" },
    { field: "name", label: "Name" },
  ],
  "google-ads": [
    { field: "manager", label: "Account Id" },
    { field: "descriptive_name", label: "Name" },
  ],
  "tiktok-ads": [{ field: "id", label: "Account Id" }],
};
