import { Checkbox, Flex, Icon } from "@tw/ui-components";

export const getPopoverItems = (
  filterTiles: string[],
  updateFilterTiles: (service: string) => void
) => {
  const popoverItems = [
    {
      content: "All",
      accessibilityLabel: "Meta",
      onAction: () => {
        updateFilterTiles("All");
      },
    },
    {
      content: (
        <Flex gap="sm">
          <Checkbox
            checked={filterTiles.includes("facebook-ads")}
            onClick={() => updateFilterTiles("facebook-ads")}
          />
          <Icon name="facebook-circle" size={20} />
          Meta
        </Flex>
      ),
      accessibilityLabel: "Meta",
      onAction: () => {
        updateFilterTiles("facebook-ads");
      },
    },
    {
      content: (
        <Flex gap="sm">
          <Checkbox
            checked={filterTiles.includes("google-ads")}
            onClick={() => updateFilterTiles("google-ads")}
          />
          <Icon name="google" size={20} />
          Google
        </Flex>
      ),
      accessibilityLabel: "Google",
      onAction: () => {
        updateFilterTiles("google-ads");
      },
    },
    {
      content: (
        <Flex gap="sm">
          <Checkbox
            checked={filterTiles.includes("tiktok-ads")}
            onClick={() => updateFilterTiles("tiktok-ads")}
          />
          <Icon name="tiktok" size={20} />
          Tiktok
        </Flex>
      ),
      accessibilityLabel: "Tiktok",
      onAction: () => {
        updateFilterTiles("tiktok-ads");
      },
    },
    {
      content: (
        <Flex gap="sm">
          <Checkbox
            checked={filterTiles.includes("triple-whale")}
            onClick={() => updateFilterTiles("triple-whale")}
          />
          <Icon name="triple-whale-logo" size={20} />
          Triple Whale
        </Flex>
      ),
      accessibilityLabel: "Triple Whale",
      onAction: () => {
        updateFilterTiles("triple-whale");
      },
    },
  ];
  return popoverItems;
};
