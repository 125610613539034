export const useQueryParams = (query = null) => {
  const updateQueryParam = (key: string, value: string) => {
    const params = new URLSearchParams(query || window.location.search);
    params.set(key, value);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
    // Returning the updated query params
    return params;
  };

  const result = {} as any;
  new URLSearchParams(query || window.location.search).forEach((value, key) => {
    result[key] = value;
  });

  return { queryParams: result, updateQueryParam };
};
