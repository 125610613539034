import React from 'react';
const FacebookIconBase64 = ({ small }: {small: boolean}) => (
  <img
    src="
  data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDUuODYgNDQ1Ljg2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzE4NzdmMjt9LmNscy0ye2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9ImZhY2Vib29rIj48ZyBpZD0iTGF5ZXJfMi0yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJfMDEuZmFjZWJvb2siIGRhdGEtbmFtZT0iIDAxLmZhY2Vib29rIj48Y2lyY2xlIGlkPSJiYWNrZ3JvdW5kIiBjbGFzcz0iY2xzLTEiIGN4PSIyMjIuOTMiIGN5PSIyMjIuOTMiIHI9IjIyMi45MyIvPjxwYXRoIGlkPSJpY29uIiBjbGFzcz0iY2xzLTIiIGQ9Ik0yOTMuNTYsMTk2LjUzbC00LjQ5LDM4Ljg0YTcuMjYsNy4yNiwwLDAsMS03LjE0LDYuNTFIMjQxLjRMMjQxLjE5LDM1N2E1LjE5LDUuMTksMCwwLDEtNS4wNiw1LjI0SDE5NS4wNkE1LjA2LDUuMDYsMCwwLDEsMTkwLDM1Ny4yYTEuNDgsMS40OCwwLDAsMSwwLS4yMVYyNDEuODhIMTU5LjZhNy4yNyw3LjI3LDAsMCwxLTcuMi03LjM1aDBsLS4xNS0zOC44M2E3LjI5LDcuMjksMCwwLDEsNy4xOS03LjRIMTkwVjE1MC44MWMwLTQzLjUzLDI1Ljg0LTY3LjIxLDYzLjczLTY3LjIxaDMxLjA2QTcuMjksNy4yOSwwLDAsMSwyOTIsOTF2MzIuN2E3LjI5LDcuMjksMCwwLDEtNy4xOSw3LjRIMjY1Ljc0Yy0yMC41OSwwLTI0LjYsMTAuMTQtMjQuNiwyNC43OFYxODguM2g0NS4yN2E3LjMsNy4zLDAsMCwxLDcuMjEsNy4zOXEwLC40Mi0uMDYuODRaIi8+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==
"
    style={{ height: small ? 15 : 20 }}
    alt="Facebook icon"
  />
);

export default FacebookIconBase64;
