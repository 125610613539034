import { IndustryTypes } from '@tw/types/module/types/IndustryType'

export type IndustryElement<I extends IndustryTypes> = {
  id: I
  label: string
}

export type IndustryDictionary = {
  [industry in IndustryTypes]: IndustryElement<industry>
}

const INDUSTRIES: IndustryDictionary = {
  all: {
    id: 'all',
    label: 'All',
  },
  art: {
    id: 'art',
    label: 'Art',
  },
  baby: {
    id: 'baby',
    label: 'Baby',
  },
  books: {
    id: 'books',
    label: 'Books',
  },
  shoes: {
    id: 'shoes',
    label: 'Shoes',
  },
  crafts: {
    id: 'crafts',
    label: 'Crafts',
  },
  clothing: {
    id: 'clothing',
    label: 'Clothing',
  },
  computers: {
    id: 'computers',
    label: 'Computers',
  },
  electronics: {
    id: 'electronics',
    label: 'Electronics',
  },
  collectibles: {
    id: 'collectibles',
    label: 'Collectibles',
  },
  pet_supplies: {
    id: 'pet_supplies',
    label: 'Pet Supplies',
  },
  home_garden: {
    id: 'home_garden',
    label: 'Home & Garden',
  },
  sporting_goods: {
    id: 'sporting_goods',
    label: 'Sporting & Goods',
  },
  toys_hobbies: {
    id: 'toys_hobbies',
    label: 'Toys & Hobbies',
  },
  health_beauty: {
    id: 'health_beauty',
    label: 'Health & Beauty',
  },
  office_products: {
    id: 'office_products',
    label: 'Office Products',
  },
  digital_products: {
    id: 'digital_products',
    label: 'Digital Products',
  },
  food_beverages: {
    id: 'food_beverages',
    label: 'Food & Beverages',
  },
  car_truck_parts: {
    id: 'car_truck_parts',
    label: 'Car & Truck Parts',
  },
  jewelry_watches: {
    id: 'jewelry_watches',
    label: 'Jewelry & Watches',
  },
  fashion_accessories: {
    id: 'fashion_accessories',
    label: 'Fashion Accessories',
  },
  cellphones_accessories: {
    id: 'cellphones_accessories',
    label: 'Cell Phones & Accessories',
  },
  hair_braids: {
    id: 'hair_braids',
    label: 'Hair & Braids',
  }
}

export default INDUSTRIES
