// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getAuth, User as FirebaseUser } from "firebase/auth";
import firebase from "firebase/compat/app";
import { isProduction } from ".";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigTripleStaging = {
  apiKey: "AIzaSyCebcxwupJdqKICfXhpErs5L5euGgpp2W8",
  authDomain: "triple-whale-staging.firebaseapp.com",
  databaseURL: "https://triple-whale-staging.firebaseio.com",
  projectId: "triple-whale-staging",
  storageBucket: "triple-whale-staging.appspot.com",
  messagingSenderId: "89529659540",
  appId: "1:89529659540:web:7ec70450c9557fdddf0481",
  measurementId: "G-SBCM18SR29",
};

const firebaseConfigTriple = {
  apiKey: "AIzaSyA8u9F2mgVXJymrXApBAyRijNn9-z60Xio",
  authDomain: "shofifi.firebaseapp.com",
  databaseURL: "https://shofifi.firebaseio.com",
  projectId: "shofifi",
  storageBucket: "shofifi.appspot.com",
  messagingSenderId: "1072436220726",
  appId: "1:1072436220726:web:6b056c3c2c44710a6f9997",
  measurementId: "G-ZG3XHCNW3L",
};

// Initialize Firebase
export const app = firebase.initializeApp(
  isProduction ? firebaseConfigTriple : firebaseConfigTripleStaging
);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export type User = FirebaseUser;
