import axiosInstance from "../utils/axiosInstance";
import { Reducer } from "redux";
import { convertDataToStatsBenchmarks } from "@tw/stats/module/services/benchmarks-new";
import { BenchmarksRequest } from "@tw/types/module/services/business-intelligence";
import { Granularity } from "@tw/types";
import { Services } from "../constants/general"
import moment from "moment";

export const FETCH_BENCHMARKS = "FETCH_BENCHMARKS";
export const FETCH_BENCHMARKS_SUCCESS = "FETCH_BENCHMARKS_SUCCESS";
export const FETCH_BENCHMARKS_PAST_PERIOD_SUCCESS =
  "FETCH_BENCHMARKS_PAST_PERIOD_SUCCESS";
export const FETCH_BENCHMARKS_ERROR = "FETCH_BENCHMARKS_ERROR";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const TOGGLE_CHART = 'TOGGLE_CHART';
export const FILTER_TILES = 'FILTER_TILES';


export const fetchBenchmarks = () => ({
  type: FETCH_BENCHMARKS,
});

export const fetchBenchmarksSuccess = (benchmarks: any) => ({
  type: FETCH_BENCHMARKS_SUCCESS,
  payload: benchmarks,
});

export const fetchBenchmarksPastPeriodSuccess = (
  benchmarksPastPeriod: any
) => ({
  type: FETCH_BENCHMARKS_PAST_PERIOD_SUCCESS,
  payload: benchmarksPastPeriod,
});

export const fetchBenchmarksError = (error: any) => ({
  type: FETCH_BENCHMARKS_ERROR,
  payload: error,
});

export const onDateRangeChanged = (dateRange: any, compareRange: any) => ({
  type: SET_DATE_RANGE,
  payload: {dateRange,  compareRange},
});

export const showChart = (state = false, action: any) => {
  switch (action.type) {
    case TOGGLE_CHART:
      return !state;
    default:
      return state;
  }
};

export const filterTiles = (state = false, action: any) => {
  switch (action.type) {
    case FILTER_TILES:
      return !state;
    default:
      return state;
  }
};

export const fetchBenchmarksAsync = (
  params: BenchmarksRequest,
  groupBy: Exclude<Granularity, "total">
) => {
  const {
    start,
    end,
    aov_segment,
    revenue_segment,
    category,
    user,
    compare_start,
    compare_end,
  } = params || {};

  
  return async (dispatch: any) => {
    dispatch(fetchBenchmarks());    
    try {
      const d = await axiosInstance.post(
        "bi/avg-trends?is_new=true",
        {
          category,
          aov_segment,
          revenue_segment,
          start,
          end,
          compare_start,
          compare_end,
        },
        {
          headers: {
            user: user,
            Authorization: `Bearer ${user}`,
          },
        }
      );
      
      const stats = convertDataToStatsBenchmarks(d.data);
      const statsPastPeriod = convertDataToStatsBenchmarks(
        d.data,
        "pastPeriod"
      );      
      dispatch(fetchBenchmarksSuccess(stats));
      dispatch(fetchBenchmarksPastPeriodSuccess(statsPastPeriod));
    } catch (error) {
      dispatch(fetchBenchmarksError(error));
    }
  };
};

export const initialState = {
  loading: false,
  benchmarks: [],
  benchmarksPastPeriod: [],
  error: null,
  dateRange: {
    start: moment().subtract(8, "days"),
    end: moment().subtract(1, 'days'),
  },
  compareRange: {
    start: moment().subtract(15, "days"),
    end: moment().subtract(8, 'days'),
  },
  showChart: false,
  filterTiles: Object.keys(Services)
};

export const benchmarksReducer: Reducer<typeof initialState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case FETCH_BENCHMARKS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BENCHMARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarks: action.payload,
      };
    case FETCH_BENCHMARKS_PAST_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        benchmarksPastPeriod: action.payload,
      };
    case FETCH_BENCHMARKS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        ...action.payload,
      };
    case TOGGLE_CHART:
      return {
        ...state,
        showChart: action.payload,
      };
      case FILTER_TILES:
        return {
          ...state,
          filterTiles: action.payload,
        };      
    default:
      return state;
  }
};
