import { Reducer } from "redux";
import { User } from "firebase/auth";
import axiosInstance from "../utils/axiosInstance";
import firebase from "firebase/compat/app";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const FIREBASE_USER = "FIREBASE_USER";

export const reportToHubspot = async (data: any) => {
  const email = firebase.auth()?.currentUser?.email;
  await axiosInstance.post("bi/hubspot-update", {
      ...data,
      email,
    });
};

export const loginSuccess = (user?: User) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

export const firebaseUser = (user?: User) => ({
  type: FIREBASE_USER,
  payload: user,
});

export const initialState = {
  user: { email: "" },
  loading: false,
  error: { message: "" },
};

export const userReducer: Reducer<typeof initialState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case FIREBASE_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    default:
      return state;
  }
};
