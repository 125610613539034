import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { Popover, OptionList, Button } from "@shopify/polaris";
import { useCallback, useState } from "react";

type DropDownProps = {
  options: OptionDescriptor[];
  onChange: (value: string) => void;
  value: string;
  label?: string;
};

export const DropDown: React.FC<DropDownProps> = ({
  options,
  onChange,
  value,
  label,
}) => {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleSelection = useCallback(
    (value: string[]) => {
      onChange(value[0]);
      setActive(false);
    },
    [onChange]
  );

  const activator = (
    <Button onClick={toggleActive}>
      {label ? label : ""}
      {options.find((option) => option.value === value)?.label?.toString() || ""}
    </Button>
  );

  return (
    <Popover
      active={active}
      activator={activator}
      onClose={toggleActive}
      preferredAlignment="left"
    >
      <OptionList
        onChange={handleSelection}
        options={options}
        selected={[value]}
      />
    </Popover>
  );
};
