import { ICONS_NAMES, Services } from "../constants/general";
import { formatNumber } from "../utils/formatNumber";
import { TooltipProps } from "recharts";
import { IconProps } from "../types/types";
import { Icon } from "@tw/ui-components";

export const CustomLineTooltip = (
  props: TooltipProps<any, any> & {
    data: any[];
    isFacebookConnected: boolean;
    isGoogleAdsConnected: boolean;
    isTiktokAdsConnected: boolean;
    currentDate: string;
    pastPeriodDate: string;
  }
) => {
  const {
    active,
    payload,
    data,
    isGoogleAdsConnected,
    isFacebookConnected,
    isTiktokAdsConnected,
    currentDate,
    pastPeriodDate
  } = props;
  if (!active) {
    return null;
  }

  const allItemsInChart: any[] = Object.keys(payload?.[0]?.payload || {});
  const allItemsInChartValue: any[] = Object.values(
    payload?.[0]?.payload || {}
  );
  if (!allItemsInChart?.length) {
    return null;
  }

  return (
    <div className="bg-white rounded p-4 custom-line-chart-tooltip shadow-md">
      <div className="flex flex-row">
        <div className="font-bold mb-4 flex flex-col" style={{ minWidth:'170px' }}>{currentDate}</div>
        <div className="font-bold mb-4 flex flex-col" >{pastPeriodDate}</div>
      </div>
      <div className="list-none p-0 m-0 flex flex-col gap-4">
        {allItemsInChart.map((c, i) => {
          const [provider, key, user] = c.split("_");
          const isUserData =
            (provider === "google-ads" && isGoogleAdsConnected) ||
            (provider === "facebook-ads" && isFacebookConnected) ||
            (provider === "tiktok-ads" && isTiktokAdsConnected);
          const item = data?.find(
            (el) => el?.key === key && el.provider === provider
          );
          if (!item || !key || user) return null;
          return (
            <div
              className="flex gap-4 items-left justify-left text-base"
              key={`${key}_${i}`}
            >
              <div className={`flex flex-col`} style={{ flex: '50%' }}>
                <div className="flex gap-2 items-center text-xs mb-1">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border rounded-md h-4 w-6 box-content "
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></div>
                  <div className="flex flex-col justify-center mx-1">
                    <Icon
                      name={
                        ICONS_NAMES[
                          provider as keyof typeof Services
                        ] as IconProps
                      }
                      size={14}
                    />
                  </div>
                  <span>{item?.label}</span>
                </div>
                <div className="flex gap-2 items-center text-xs mb-1">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border rounded-md h-1 w-6 box-content "
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></div>
                  <span>Benchmark</span>
                  <span>
                    {formatNumber(
                      item.format === "percent"
                        ? allItemsInChartValue[i] / 100
                        : allItemsInChartValue[i],
                      {
                        style: item.format || "decimal",
                        currency: "USD",
                        minimumFractionDigits: item.toFixed || 0,
                        maximumFractionDigits: item.toFixed || 0,
                      }
                    )}
                  </span>
                </div>
                {isUserData && (
                  <div className="flex gap-2 items-center text-xs">
                    <div
                      className="rounded-md h-1 w-2 box-content"
                      style={{
                        backgroundColor: item.color,
                      }}
                    ></div>
                    <div
                      className="rounded-md h-1 w-2 box-content"
                      style={{
                        backgroundColor: item.color,
                      }}
                    ></div>
                    <span>Your Data</span>
                    <span>
                      {formatNumber(
                        item.format === "percent"
                          ? allItemsInChartValue[i + 2] / 100
                          : allItemsInChartValue[i + 2],
                        {
                          style: item.format || "decimal",
                          currency: "USD",
                          minimumFractionDigits: item.toFixed || 0,
                          maximumFractionDigits: item.toFixed || 0,
                        }
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className={`flex flex-col`} style={{ flex: '50%' }}>
                <div className="flex gap-2 items-center text-xs mb-1">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border rounded-md h-4 w-6 box-content "
                    style={{
                      backgroundColor: item.color,
                      opacity: 0.5,
                    }}
                  ></div>
                  <div className="flex flex-col justify-center mx-1">
                    <Icon
                      name={
                        ICONS_NAMES[
                          provider as keyof typeof Services
                        ] as IconProps
                      }
                      size={14}
                    />
                  </div>
                  <span>{item?.label}</span>
                </div>
                <div className="flex gap-2 items-center text-xs mb-1">
                  <div
                    className="grid grid-cols-1 grid-rows-1 border rounded-md h-1 w-6 box-content "
                    style={{
                      backgroundColor: item.color,
                      opacity: 0.5,
                    }}
                  ></div>
                  <span>Benchmark</span>
                  <span>
                    {formatNumber(
                      item.format === "percent"
                        ? allItemsInChartValue[i + 1] / 100
                        : allItemsInChartValue[i + 1],
                      {
                        style: item.format || "decimal",
                        currency: "USD",
                        minimumFractionDigits: item.toFixed || 0,
                        maximumFractionDigits: item.toFixed || 0,
                      }
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
