import animationData from '../lotties/dots-loader.json';
import Lottie, { LottieProps } from 'react-lottie-player';

export const Loading: React.FC<LottieProps> = (props) => {
  const { style = { width: 160, height: 120 } } = props;
  return (
    <Lottie
      loop
      play
      animationData={animationData}
      style={style}
      rendererSettings={{ preserveAspectRatio: 'xMinYMin slice' }}
    />
  );
};
