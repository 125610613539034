import statsSelectors from "@tw/stats";
import { Chart } from "../types/types";
import { MetricsWithSelector } from "@tw/types/module/metrics/benchmarkMetrics";
import { AbstractChart } from "@tw/types/module/types/chart";
import moment from "moment";

function extractYear(dateString: string) {
  let parts = dateString.split(", ");
  let yearPart = parts[1];
  let year = parseInt(yearPart, 10);
  return year;
}

export const convertedDataToChart = (
  benchmarks: Array<{ id: string }>,
  benchmarksPastPeriod: Array<{ id: string }>,
  userMetrics: Array<{ id: string }>,
  selectedMetrics: MetricsWithSelector<any>[]
) => {
  return Object.keys(benchmarks).map((key: string, index) => {
    const item = benchmarks[key as any];
    const rowData = {
      name: moment(key).format("MMM DD"),
    };    
    selectedMetrics.forEach((metric) => {
      const { provider, key } = metric;
      const chartData = statsSelectors[metric.chart]({
        newStats: { benchmarks },
      }) as Number[];
      const chartDataPastPeriod = statsSelectors[metric.chart]({
        newStats: { benchmarks: benchmarksPastPeriod },
      }) as Number[];
      console.log("Data",{  benchmarks, benchmarksPastPeriod, chartData, chartDataPastPeriod});
      
      const userChartData = !!metric.userChart
        ? (statsSelectors as any)[metric.userChart]({
            newStats: { ...userMetrics },
          })
        : 0;
      let currentValueInitialLength = chartData[index] || 0;
      let currValueLength = parseFloat(currentValueInitialLength.toFixed(2));
      let currentValueInitialLengthPastPeriod =
        chartDataPastPeriod[index] || 0;
      let currValueLengthPastPeriod = parseFloat(
        currentValueInitialLengthPastPeriod.toFixed(2)
      );
      let userCurrentValueInitialLength =
        (userChartData as Chart[])[index]?.y || 0;
      let userCurrValueLength = parseFloat(
        userCurrentValueInitialLength.toFixed(2)
      );
      (rowData as any)[`${provider}_${key}`] = currValueLength;
      (rowData as any)[`${provider}_${key}_past_period`] =
        currValueLengthPastPeriod;
      (rowData as any)[`${provider}_${key}_user`] = userCurrValueLength;
    });
    return rowData;
  });
}