import React, { useState } from 'react';
import { Modal, List } from '@shopify/polaris';
import { ServicesId, UserAccounts } from "../types/types"
import { Services } from '../constants/general';


interface EmailChooserModalProps {
    userAccounts: UserAccounts;
    serviceId: ServicesId;
    onEmailSelected: (email: string) => void;
    onCancel: () => void;
  }
  
  export const AccountChooserModal: React.FC<EmailChooserModalProps> = ({
    userAccounts,
    serviceId,
    onEmailSelected,
    onCancel,
  }) => { 
    const [selectedName, setSelectedName] = useState(Object.keys(userAccounts)[0].split('/')[1]);
    return (
      <Modal
        open={true} 
        onClose={onCancel}
        title={`Choose an ${Services[serviceId].name} Account`}
        primaryAction={{
          content: 'Select',
          onAction: () => {
            onEmailSelected(selectedName);
          },
        }}
      >
        <Modal.Section>
        <List>
          { Object.keys(userAccounts).map((customerId: any) => {
            const customer = userAccounts[customerId];
              return (
                <div
                  key={customerId}
                  onClick={() => setSelectedName(customerId)}
                  className={
                    selectedName === customerId ? 'gray-background' : ''
                  }
                >
                  <Modal.Section>
                    <p>{customer.descriptive_name || customerId}</p>
                  </Modal.Section>
                </div>
              );
          })}
        </List>
        </Modal.Section>
      </Modal>
    );
  }
  
