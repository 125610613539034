import { Auth, User, signOut } from "@firebase/auth";
import { Frame, Layout, Page, Text, TopBar, Toast } from "@shopify/polaris";
import { UserMenuProps } from "@shopify/polaris/build/ts/src/components/TopBar";
import { useCallback, useRef, useState, useEffect } from "react";
import { Benchmarks } from "./Benchmarks";
import { Login } from "./Login";
import { getAdsCustomers } from "../ducks/auth";
import {
  UserAccounts,
  ServicesId,
  FacebookAccountObject,
} from "../types/types";
import { AccountChooserModal } from "./AccountChooserModal";
import { loginSuccess } from "../ducks/user";
import { setServiceAccountInfo } from "../ducks/integrations";
import { RootState, useAppDispatch, useAppSelector } from "..";
import {
  errorMessages,
  FACEBOOK_UNKNOWN_ERROR,
  TIKTOK_UNKNOWN_ERROR,
} from "../constants/errorMsg";
import {
  CONNECT_STATUS,
  fetchMediaAccounts,
  hidePopup,
  updateAccountData,
} from "../ducks/accounts";
import { fetchUserMetrics } from "../ducks/userMetrics";
import Prompt from "./Prompt";
import { Loader } from "@tw/ui-components";
import UpgradeModal from "./UpgradeModal";
import { useQueryParams } from "../utils/useQueryParams";
import { convertAcountInfo } from "../utils/convertAccountInfo";
import { FILTERS } from "../constants/general";

type MainFrameProps = {
  user: User;
  auth: Auth;
};

export const MainFrame: React.FC<MainFrameProps> = ({ user, auth }) => {
  const dispatch = useAppDispatch();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const {
    googleAds,
    facebookAds,
    tiktokAds,
    shouldGooglePopup,
    shouldFacebookPopup,
    shouldTiktokPopup,
    promptSettings,
    connectedStatus,
    tiktokAccessToken,
  } = useAppSelector((state: any) => state.accounts);

  const { dateRange } = useAppSelector((state: RootState) => state.benchmarks);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [userAccounts, setUserAccounts] = useState<UserAccounts>([]);
  const [accountsData, setAccountsData] = useState<any>([]);
  const [serviceId, setServiceId] = useState<ServicesId>();
  const [loading, setLoading] = useState(false);
  const openPrompt = !!promptSettings.title;
  const { queryParams } = useQueryParams();

  useEffect(() => {
    Object.keys(queryParams).forEach((key) => {
      const obj = FILTERS.find((filter) => filter.id === key);
      console.log(key, queryParams[key], obj);
      const valueProperCase =
        obj?.id === "industry"
          ? queryParams[key]?.toLowerCase()
          : queryParams[key].toUpperCase();
      if (obj && obj.list.includes(valueProperCase)) {
        dispatch(
          updateAccountData({ [obj.field]: valueProperCase })
        );
      }
    });
    //Don't add queryParams to the dependency array. It will cause infinite loop.
  }, []);

  const modalAccounts = useCallback(
    async (shop: string, srvId: ServicesId) => {
      setLoading(true);
      const data = await getAdsCustomers(shop, srvId, tiktokAccessToken);
      setAccountsData(data?.accountList || data?.result);
      const accountList = convertAcountInfo(srvId, data);
      setLoading(false);
      if (data?.errorCode) {
        let errorMsg: string =
          srvId === "google-ads"
            ? errorMessages[data.errorCode] || errorMessages.UNKNOWN_ERROR
            : srvId === "tiktok-ads"
            ? TIKTOK_UNKNOWN_ERROR
            : FACEBOOK_UNKNOWN_ERROR;
        dispatch({
          type: CONNECT_STATUS,
          payload: {
            ...connectedStatus,
            [srvId]: { status: "error", msg: errorMsg },
          },
        });
      } else if (Object.keys(accountList || {}).length) {
        setUserAccounts(accountList);
        setIsModalOpen(true);
        setServiceId(srvId);
        dispatch({
          type: CONNECT_STATUS,
          payload: {
            ...connectedStatus,
            [srvId]: { status: "success" },
          },
        });
      }
    },
    [tiktokAccessToken]
  );

  useEffect(() => {
    dispatch(fetchMediaAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (!user) return;
    const currentShopId = `tw-trends-${user?.uid}`;

    if (shouldGooglePopup) {
      modalAccounts(currentShopId, "google-ads");
    } else if (shouldTiktokPopup) {
      modalAccounts(currentShopId, "tiktok-ads");
    } else if (shouldFacebookPopup) {
      modalAccounts(currentShopId, "facebook-ads");
    }
  }, [
    shouldFacebookPopup,
    shouldGooglePopup,
    shouldTiktokPopup,
    user,
    modalAccounts,
  ]);

  useEffect(() => {
    const googleAccountsArr = Object.keys(googleAds);
    let facebookAccountsArr = [],
      tiktokAccountsArr = [""];
    if (Array.isArray(facebookAds)) {
      facebookAccountsArr = facebookAds.map((account) => account.id);
    }
    if (Array.isArray(tiktokAds)) {
      tiktokAccountsArr = tiktokAds.map((account) => `${account.id}`);
    }
    if (googleAccountsArr.length) {
      dispatch(
        fetchUserMetrics(
          "google-ads",
          googleAccountsArr.map((id) => id.split("/")[1])
        )
      );
    }
    if (facebookAccountsArr.length) {
      dispatch(fetchUserMetrics("facebook-ads", facebookAccountsArr));
    }
    if (tiktokAccountsArr.length) {
      dispatch(fetchUserMetrics("tiktok-ads", tiktokAccountsArr));
    }
  }, [
    dispatch,
    facebookAds,
    googleAds,
    tiktokAds,
    dateRange.start,
    dateRange.end,
  ]);

  const logout = useCallback(async () => {
    await signOut(auth);
    dispatch(loginSuccess());
    localStorage.removeItem("selectedAccount");
  }, [dispatch, auth]);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    []
  );

  const handleAccountSelected = (manager: string) => {
    setSelectedAccount(manager);
    setIsModalOpen(false);
    localStorage.setItem("selectedAccount", manager);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const setAccountData = async () => {
      if (selectedAccount) {
        const accountId =
          Object.keys(userAccounts).find((acc) => acc === selectedAccount) ||
          "";
        const currentShopId = `tw-trends-${user?.uid}`;
        let account, accountInfo;
        if (serviceId === "google-ads") {
          account = userAccounts[accountId as any];
          accountInfo = {
            googleAdsAccounts: {
              [accountId]: account,
            },
          };
        } else if (serviceId === "tiktok-ads") {
          account = {
            id: userAccounts[accountId as any].manager,
            selected: true,
          };
          accountInfo = {
            tiktokAccounts: [account],
          };
        } else if (serviceId === "facebook-ads") {
          account = accountsData.find(
            (account: FacebookAccountObject) =>
              account?.account_id === selectedAccount
          );
          accountInfo = {
            facebookAccounts: [account],
            facebookAttributionWindows: "7d_click,1d_view",
          };
        }
        dispatch(hidePopup(serviceId as ServicesId));
        await setServiceAccountInfo(
          currentShopId,
          serviceId as ServicesId,
          accountInfo
        );
        dispatch(fetchMediaAccounts());
      }
    };
    setAccountData();
  }, [selectedAccount, accountsData, userAccounts, user, serviceId, dispatch]);

  const logo = {
    width: 30,
    topBarSource: "/master/trends/tw-square-logo.svg",
    contextualSaveBarSource: "/master/trends/tw-square-logo.svg",
    url: "#",
    accessibilityLabel: "Jaded Pixel",
  };

  const userMenuActions: UserMenuProps["actions"] = [
    {
      items: [{ content: "Logout", onAction: logout }],
    },
  ];

  const skipToContentTarget = (
    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
    <a id="SkipToContentTarget" ref={skipToContentRef} tabIndex={-1} />
  );
  const userMenuMarkup = (user || {}).email ? (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={user.displayName || user.email || ""}
      detail={user.email || ""}
      avatar={user.photoURL || undefined}
      initials={
        user.displayName ? user.displayName[0] : user.email ? user.email[0] : ""
      }
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : (
    <div className="Polaris-TopBar-Menu__ActivatorWrapper" />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle={false}
      userMenu={userMenuMarkup}
      logoSuffix={
        <div className="mt-1" style={{ display: "flex", width: "900px" }}>
          <Text as="p" variant="headingMd">
            Triple Whale
          </Text>
        </div>
      }
    />
  );
  return (
    <Frame
      logo={logo}
      topBar={topBarMarkup}
      skipToContentTarget={skipToContentRef}
    >
      <Page fullWidth>
        {loading && Object.keys(queryParams).length > 0 && (
          <div className="absolute top-1/3 left-1/2 z-10 ">
            <Loader />{" "}
          </div>
        )}

        <div className="flex justify-between align-center mb-2">
          <div>
            <div className="text-2xl font-semibold">
              Ecommerce Industry Benchmarks
            </div>
            <div className="text-sm align-middle mb-2">
              {user
                ? "Link your ad accounts to see how your brand stacks up against specific cohorts or the entire ecommerce industry."
                : "Explore performance data from 10,000+ stores in the Shopify ecosystem."}
            </div>
          </div>
          {!(user || {}).email && <Login />}
        </div>
        <Layout>
          {skipToContentTarget}
          <Layout.Section>
            <Benchmarks />
          </Layout.Section>
        </Layout>
        {isModalOpen && Object.keys(userAccounts).length > 0 && (
          <AccountChooserModal
            userAccounts={userAccounts}
            onEmailSelected={handleAccountSelected}
            onCancel={handleCancel}
            serviceId={serviceId as ServicesId}
          />
        )}
        <UpgradeModal />
        {/* {errorIntegration && !openPrompt && (
          <>
            <Toast
              duration={10000}
              content={
                (
                  <div style={{ fontSize: "14px" }}>
                    <Text as="p" variant="headingSm">
                      {errorIntegration}
                    </Text>
                  </div>
                ) as any
              }
              onDismiss={() => {
                setErrorIntegration("");
              }}
            />
          </>
        )} */}
        {openPrompt && <Prompt />}
      </Page>
    </Frame>
  );
};
