type CircleIconProps = {
  color: 'red' | 'green' | 'orange';
};

export const CircleIcon: React.FC<CircleIconProps> = ({ color }) => {
  return (
    <div
      className={`w-2 h-2 rounded-full ${
        color === 'green' ? 'bg-green' : color === 'red' ? 'bg-red-500' : 'bg-orange-400'
      }`}
    />
  );
};
