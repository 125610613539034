export const errorMessages: { [key: string]: string } = {
    ACCESS_TOKEN_SCOPE_INSUFFICIENT:
      'Looks like we weren’t given the necessary level of permission to your Google Ads account. Please try to connect your account again',
    INVALID_GRANT:
      'You are not currently logged in to Google Ads, so we cannot connect your account. Log back into your account and try again',
    AUTHENTICATION_ERROR_14:
      "You don't seem to have access to any Google Ads accounts. Contact your administrator to get permissions and try again",
    UNKNOWN_ERROR:
      'We were unable to connect your Google Ads accounts. Please try again or contact our support team',
  };

  export const FACEBOOK_UNKNOWN_ERROR = 'We could not fetch a Facebook Ads Accounts list. Please contact our support team';

  export const TIKTOK_UNKNOWN_ERROR = 'Something went wrong with your Tiktok account. Please try re-connecting';