import { User } from "firebase/auth";
import { useCallback } from "react";
import { useAppDispatch } from "..";
import { loginSuccess } from "../ducks/user";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleClientId } from "../config";
import { LoginWithGoogleButton } from "./SSO/LoginWithGoogleButton";

type SSOButtonProps = {
  onLoginSuccess: (response: any) => void;
  signIn?: boolean;
  comparisonEmail?: string;
  transparentButton?: boolean;
};
export const SSOButtons: React.FC<SSOButtonProps> = ({
  onLoginSuccess,
  signIn,
  comparisonEmail,
  transparentButton,
}) => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
        <LoginWithGoogleButton
          onLoginSuccess={onLoginSuccess}
          signIn={signIn}
          transparentButton={transparentButton}
          comparisonEmail={comparisonEmail}
        />
    </GoogleOAuthProvider>
  );
};

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const onLoginSuccess = useCallback(
    async (user: User) => {
      dispatch(loginSuccess(user));
    },
    [dispatch]
  );

  return <SSOButtons onLoginSuccess={onLoginSuccess} signIn={true} />;
};
