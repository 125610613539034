import { combineReducers } from "redux";
import { benchmarksReducer } from "./benchmarks";
import { userReducer } from "./user";
import { accountsReducer } from "./accounts";
import { metricsReducer } from "./userMetrics";
import { upgradeReducer } from "./upgrade";

const reducers = combineReducers({
  benchmarks: benchmarksReducer,
  user: userReducer,
  accounts: accountsReducer,
  userMetrics: metricsReducer,
  upgrade: upgradeReducer 
});

export default reducers;
