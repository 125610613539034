import { TwNumberFormatOptions } from "@tw/types";
import moment from "moment";

export const formatDuration = (seconds: number): string => {
  if (seconds < 0) {
    return '00:00:00';
  }
  const duration = moment.duration(seconds, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secs = duration.seconds();
  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    secs < 10 ? '0' : ''
  }${secs}`;
};

export const formatNumber = (value: number, options: TwNumberFormatOptions): string => {
  if (options.style === 'duration') {
    return formatDuration(value);
  }
  // if you explicitly want a string, you get a string
  if (options.style === 'string') {
    return value.toString();
  }
  if (!value || Number.isNaN(+value) || !Number.isFinite(+value)) {
    value = 0;
  }
  return value.toLocaleString(undefined, options);
};