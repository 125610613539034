import React, { useState } from "react";
import { Popover, ActionList } from "@shopify/polaris";
import { Icon, Button } from "@tw/ui-components";
interface item {
  content: any;
  onAction?: () => void;
  icon?: any;
  accessibilityLabel?: string;
}
interface props {
  items: item[];
  sections?: { title: string; items: item[] }[];
  title?: string;
}
const PopoverButton: React.FC<props> = ({ items, sections, title }) => {
  const [isPopoverActive, setIsPopoverActive] = useState(false);
  const caretDown = <Icon color="gray.9" name={"caret-down"} size={10} />;
  const activator = (
    <Button
      variant="activator"
      rightIcon={caretDown}
      onClick={() => setIsPopoverActive(!isPopoverActive)}
    >
      {title}
    </Button>
  );

  return (
    <Popover
      preventCloseOnChildOverlayClick
      active={isPopoverActive}
      activator={activator}
      onClose={() => setIsPopoverActive(!isPopoverActive)}
    >
      <ActionList items={items} sections={sections} />
    </Popover>
  );
};
export default PopoverButton;
