
import axiosInstance from '../utils/axiosInstance';
import { auth } from '../config/firebase'
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithCredential,
  } from 'firebase/auth';
  import { ServicesId } from '../types/types';


const signInWithFacebookCredentials = async (facebookAccessToken: string) => {
    const credential = FacebookAuthProvider.credential(facebookAccessToken);
    await signInWithCredential(auth, credential);
  };

const signInWithGoogleCredentials = async (googleAccessToken: string) => {
  const credential = GoogleAuthProvider.credential(null, googleAccessToken);
  await signInWithCredential(auth, credential);
};


export const signInUpWithFacebook = async (
    facebookAccessToken: string,
    signIn?: boolean,
    comparisonEmail?: string
  ) => {
    try {
      const response = await axiosInstance.post(
        `/account-manager/users/get-user-by-facebook-token`,
        {
          token: facebookAccessToken,
        }
      );
      const { user, facebookUser } = response.data || {};
      if (comparisonEmail && facebookUser.email !== comparisonEmail) {
        throw new Error(`Email: ${facebookUser.email} does not match the invitation email`);
      }
      if ((!user && !signIn) || (user && signIn)) {
        await signInWithFacebookCredentials(facebookAccessToken);
        return user;
      } else {
        throw new Error(
          `User ${user ? 'is already' : 'does not'} exist${user ? 's' : ''}. ${
            user ? 'Please sign in below' : 'Create a user by clicking "Sign Up"'
          }`
        );
      }
    } catch (e) {
      throw e;
    }
  };

  export const signInUpWithGoogle = async (
    googleAccessToken: string,
    signIn?: boolean,
    comparisonEmail?: string
  ) => {
    try {
      const response = await axiosInstance.post(
        `/account-manager/users/get-user-by-google-token`,
        {
          token: googleAccessToken,
        }
      );
      const { user, googleUser } = response.data || {};
      if (comparisonEmail && googleUser.email !== comparisonEmail) {
        throw new Error(`Email: ${googleUser.email} does not match the invitation email`);
      }
      if ((!user && !signIn) || (googleUser && signIn)) {
        await signInWithGoogleCredentials(googleAccessToken);
        return googleUser;
      } else {
        throw new Error(
          `User ${user ? 'is already' : 'does not'} exist${user ? 's' : ''}. ${
            user ? 'Please sign in below' : 'Create a user by clicking "Sign Up"'
          }`
        );
      }
    } catch (e) {
      throw e;
    }
  };

  const advertiserIds = (t: any) => {
    return t?.data?.advertiser_ids || []
  }

  export const getAdsCustomers = async (shopId: string, serviceId: ServicesId, tiktokAccessToken: string) => {
    try {
      if (serviceId === 'tiktok-ads')
        return advertiserIds(tiktokAccessToken);
      const url = `/${serviceId}/fetch/accounts?shopDomain=${shopId}`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (e) {
      console.log(`${serviceId} get an account list error`, e);
    }
  };