import { Button, Modal, Text, Center, Icon } from "@tw/ui-components";
import { hideUpgradeModal } from "../ducks/upgrade";
import { FC } from "react";
import { useAppSelector, useAppDispatch, RootState } from "..";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";

const UpgradeModal: FC = () => {
  const dispatch = useAppDispatch();
  const { open } = useAppSelector((state: RootState) => state.upgrade);
  const [user] = useAuthState(auth);
  return (
    <Modal
      size="lg"
      opened={open}
      onClose={() => dispatch(hideUpgradeModal())}
      withCloseButton={false}
    >
      <Modal.Header
        onClick={() => dispatch(hideUpgradeModal())}
        id="upgrade-modal-header"
      >
        <Icon name="close" id="upgrade-modal-close" size={10}></Icon>
      </Modal.Header>
      <Modal.Body>
        <center>
          <Text color="one.6" size={18} weight={500}>Try Triple Whale For Free</Text>
        </center>
        <center>
          <Text size={40} weight={600} lh={1} mt="sm">
          Better Data.
          </Text>
          <Text size={40} weight={600} lh={1} mb="sm">
          Better Decisions.
          </Text>
        </center>
        <Text align="center" display="flex" size={18} weight={400}>
           Access the real-time insights you need to grow your brand today. 
           Anywhere. Any time.
        </Text>
        <Center>
          <Button
            px="xl"
            m="md"
            onClick={() => 
            {
              const urlSignFree = `https://app.triplewhale.com/signup-free?lead=trends&uid=${user?.uid}`;
              window.open(urlSignFree, '_blank');
            }}
          >
            Try For Free
          </Button>
        </Center>
        <Center>
          <Text
            align="center"
            display="flex"
            size={14}
            weight={500}
            color="one.6"
            underline={true}
          >
            Meet with an expert
          </Text>
        </Center>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeModal;
