import { Reducer } from "redux";
import axiosInstance, { getUserId } from "../utils/axiosInstance";
import { ServicesId } from "../types/types";
import { convertDataToStats } from "../utils/convertToStats";

export const fetchUserMetrics = (service_id: ServicesId, account_ids: string[]) => {
  return async (dispatch: any, getStats: any) => {
    const { dateRange } = getStats().benchmarks;
    const params = {
      account_ids,
      currency: "USD",
      data_type: "ads-metrics",
      start: dateRange.start.format("YYYY-MM-DD"),
      end: dateRange.end.format("YYYY-MM-DD"),
  granularity: "day",
      service_id: service_id,
      shop: `tw-trends-${getUserId()}`,
      shopId: `tw-trends-${getUserId()}`,
    };
    const { data } = await axiosInstance.post(
      "/metrics-table/get-metrics",
      params
    );
    const metrics = convertDataToStats(data);
    return dispatch({
      type: "FETCH_USER_METRICS",
      payload: {service_id, metrics}
    });
  };
};

export const metricsReducer: Reducer = (state = {}, action: any) => {
  switch (action.type) {
    case "FETCH_USER_METRICS": {      
      return { ...state, [action.payload.service_id]: action.payload.metrics };
    }
    default:
      return state;
  }
};
