import posthog from 'posthog-js';
import { datadogLogs } from '@datadog/browser-logs';
import amplitude from 'amplitude-js';

import { isLocalhost, isProduction, posthogKey } from '../../config';
import { User } from '../../config/firebase';
const runEvent = !isLocalhost  && (isProduction);

posthog.init(posthogKey, {
  api_host: 'https://app.posthog.com',
  capture_pageview: false,
});

declare global {
  interface Window {
    Intercom: any;
    dataLayer: any;
    Canny: any;
    twUser: any;
    twFFConfig: any;
    twFeatures: any;
    twShop: any;
    startup: any;
    ReactNativeWebView: any;
  }
}

window.dataLayer = window.dataLayer || [];

// https://stackoverflow.com/questions/43673440/resetting-data-layer-variables-in-single-page-applications-google-tag-manager
export const boomDataLayer = () => {
  // @ts-ignore
  if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
    window.dataLayer.length = 0;
    const gtmContainerReg = /GTM-/i;

    // @ts-ignore
    for (const gtmKey of Object.keys(window.google_tag_manager)) {
      if (
        // @ts-ignore
        gtmContainerReg.test(gtmKey) &&
        // @ts-ignore
        window.google_tag_manager[gtmKey].dataLayer &&
        // @ts-ignore
        window.google_tag_manager[gtmKey].dataLayer.reset
      ) {
        // @ts-ignore
        window.google_tag_manager[gtmKey].dataLayer.reset();
      }
    }
  }
};

export const dataLayerInformAuth = (user: User) => {
  // user is firebase auth user, data is firestore user' data
  var { email, displayName, metadata, uid } = user;
  // @ts-ignore

  const gtmUser = {
    createdAt: user?.metadata?.creationTime,
    email: user?.email,
    emailVerified: user?.emailVerified,
    name: user?.displayName,
    userId: user?.uid,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'getUserData', gtmUser });

  if (isProduction) {
    amplitude.getInstance().setUserId(uid);
    amplitude.getInstance().setUserProperties({ email });
    amplitude.getInstance().logEvent('AUTH');
    posthog.identify(user.uid, { email });
  }

  if (window.Intercom) {
    window.Intercom('update', {
      app_id: 'ogniecxz',
      name: displayName,
      email: email,
      hide_default_launcher:  false,
    });
  } else {
    // console.log('AUTH IS DONE, INTERCOM IS NOT');
  }

  // @ts-ignore
  if (window.analytics) {
    // @ts-ignore
    window.analytics.identify(uid, {
      name: displayName,
      email: email,
    });
  } else {
    // console.log('AUTH IS DONE, SEGMENT IS NOT');
  }
};

export const dataLayerLogout = () => {
  if (isProduction) {
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().clearUserProperties();
    posthog.reset();
  }
};


export const genericEventLogger = (eventName: string, payload?: object) => {
  if (!runEvent) {
    console.log('genericEventLogger', eventName, payload);
    return;
  }
  try {
    window.dataLayer.push({ event: eventName, payload });
    posthog.capture(eventName, payload);
  } catch { 
  }
};

export const posthogEventLogger = (eventName: string, payload: object) => {
  if (!runEvent) {
    console.log('posthogEventLogger', eventName, payload);
    return;
  }

  try {
    posthog.capture(eventName, payload);
  } catch {}
};

export const errorBoundaryLogger = (eventName: string, payload?: object) => {
  if (!runEvent) {
    console.log('errorBoundaryLogger', eventName, payload);
    return;
  }

  try {
    datadogLogs.logger.error(eventName, payload);
    posthog.capture(eventName, payload);
  } catch {}
};

export const lazyLogger = (eventName: string, payload?: object) => {
  if (!runEvent) {
    console.log('lazyLogger', eventName, payload);
    return;
  }

  try {
    datadogLogs.logger.error(eventName, payload);
    posthog.capture(eventName, payload);
  } catch {}
};
