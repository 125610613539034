export const isLocalhost = window.location.hostname === "localhost"
export const isProduction = window.location.hostname === "app.triplewhale.com";

export const baseURL = !isProduction
  ? "https://staging.api.triplewhale.com/api/v2/"
  : `https://api.triplewhale.com/api/v2/`;
export const posthogKey = "phc_xZsKBEsKnP7rX1QvXtQapmOa9kPZQ2Q9LtaYr7aBS1u"
export const googleClientId = isProduction
  ? '1072436220726-ngrs8g3jv318j73buq6t7u6busbg8b1v.apps.googleusercontent.com'
  : '89529659540-096trej1hjnt3gqogdbj5o573e463i22.apps.googleusercontent.com';
export const facebookAppId = '221942479096091';
