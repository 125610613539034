import { Modal } from '@shopify/polaris';
import { hidePrompt } from '../ducks/accounts';
import { FC } from 'react';
import { useAppSelector, useAppDispatch, RootState } from '..';
import { PromptSettings } from '../types/types'

const Prompt: FC = () => {
  const dispatch = useAppDispatch();
  const { promptSettings } = useAppSelector((state: RootState) => state.accounts);

  const {
    title = null,
    children = null,
    primaryAction = {},
  } = promptSettings || {};

  return (
    <Modal
      open={!!promptSettings}
      onClose={() => dispatch(hidePrompt())}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            dispatch(hidePrompt());
          },
        },
      ]}
    >
      <Modal.Section>{children}</Modal.Section>
    </Modal>
  );
};

export default Prompt;
