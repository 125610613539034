import { Reducer } from "redux";
import { getUserId } from "../utils/axiosInstance";
import firebase from "firebase/compat/app";
import { ServicesId } from "../types/types";
import { PromptSettings } from "../types/types";
import { reportToHubspot } from "./user";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const HIDE_POPUP = "HIDE_POPUP";
export const CONNECT_STATUS = "CONNECT_STATUS";
export const GOOGLE_ADS_DISCONNECT_DONE = "GOOGLE_ADS_DISCONNECT_DONE";
export const FACEBOOK_ADS_DISCONNECT_DONE = "FACEBOOK_ADS_DISCONNECT_DONE";
export const TIKTOK_ADS_DISCONNECT_DONE = "TIKTOK_ADS_DISCONNECT_DONE";

const FETCH_DATA = "FETCH_DATA";
const GOOGLE_ADS_CONNECTED = "GOOGLE_ADS_CONNECTED";
const SET_OPTIMISTIC_IMPORTING_FOR_SERVICE =
  "SET_OPTIMISTIC_IMPORTING_FOR_SERVICE";
const SHOW_PROMPT = "SHOW_PROMPT";
const HIDE_PROMPT = "HIDE_PROMPT";

export const initialState = {
  googleAds: {},
  facebookAds: [],
  tiktokAds: [],
  shouldFacebookPopup: false,
  shouldGooglePopup: false,
  shouldTiktokPopup: false,
  isFacebookConnected: false,
  isGoogleAdsConnected: false,
  isTiktokAdsConnected: false,
  tiktokAccessToken: "",
  connectedStatus: {
    "google-ads": { status: "", msg: "" },
    "facebook-ads": { status: "", msg: "" },
    "tiktok-ads": { status: "", msg: "" },
  },
  selectedAOV: "",
  selectedRevenue: "",
  selectedCategory: "all",
  promptSettings: { title: "", children: <></>, primaryAction: () => {} },
};

export const hidePopup = (serviceId: ServicesId) => ({
  type: HIDE_POPUP,
  payload:
    serviceId === "google-ads"
      ? "shouldGooglePopup"
      : serviceId === "tiktok-ads"
      ? "shouldTiktokPopup"
      : "shouldFacebookPopup",
});

type UpdateAccountData = {
  selectedAOV?: string;
  selectedRevenue?: string;
  selectedCategory?: string;
};
export const updateAccountData = (values: UpdateAccountData) => {
  return async (dispatch: any, getStats: any) => {
    const userId = getUserId();
    if (userId) {
      try {
        await firebase
          .firestore()
          .collection("shops")
          .doc(`tw-trends-${userId}`)
          .update({ ...values });
        reportToHubspot({ data: { ...values, event: "settings-changed" } });
      } catch (error) {}
      dispatch({
        type: FETCH_DATA,
        payload: values,
      });

    } else {
      dispatch({
        type: FETCH_DATA,
        payload: values,
      });
    }
  };
};
export const showPrompt = (promptSettings: PromptSettings) => {
  return {
    type: SHOW_PROMPT,
    promptSettings,
  };
};

export const hidePrompt = () => {
  return {
    type: HIDE_PROMPT,
    promptSettings: initialState.promptSettings,
  };
};

export const fetchMediaAccounts = () => {
  return async (dispatch: any, getStats: any) => {
    const userId = getUserId();
    if (userId) {
      const shopData = await firebase
        .firestore()
        .collection("shops")
        .doc(`tw-trends-${userId}`)
        .get();
      dispatch({
        type: FETCH_ACCOUNTS,
        payload: shopData.data(),
      });
    }
  };
};

export const accountsReducer: Reducer<typeof initialState> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case FETCH_ACCOUNTS:
      const {
        googleAdsAccounts,
        facebookAccounts,
        tiktokAccounts,
        googleAdsAccessToken,
        facebookAccessToken,
        tiktokAccessToken,
        ...rest
      } = action.payload || {};
      return {
        ...state,
        googleAds: googleAdsAccounts || {},
        facebookAds: facebookAccounts || [],
        tiktokAds: tiktokAccounts || {},
        shouldGooglePopup:
          googleAdsAccessToken &&
          (!googleAdsAccounts || Object.keys(googleAdsAccounts).length === 0),
        shouldFacebookPopup:
          facebookAccessToken &&
          (!facebookAccounts || Object.keys(facebookAccounts).length === 0),
        shouldTiktokPopup:
          tiktokAccessToken &&
          (!tiktokAccounts || Object.keys(tiktokAccounts).length === 0),
        isFacebookConnected: !!facebookAccessToken,
        isGoogleAdsConnected: !!googleAdsAccessToken,
        isTiktokAdsConnected: !!tiktokAccessToken,
        tiktokAccessToken: tiktokAccessToken
          ? JSON.parse(tiktokAccessToken)
          : "",
        connectedStatus: {
          "google-ads": {
            status:
              googleAdsAccessToken && Array.isArray(googleAdsAccounts)
                ? "success"
                : "error",
          },
          "facebook-ads": {
            status:
              facebookAccessToken && Array.isArray(facebookAccounts)
                ? "success"
                : "error",
          },
          "tiktok-ads": {
            status:
              tiktokAccessToken && Array.isArray(tiktokAccounts)
                ? "success"
                : "error",
          },
        },
        ...rest,
      };
    case HIDE_POPUP:
      return {
        ...state,
        [action.payload]: false,
      };
    case SHOW_PROMPT:
    case HIDE_PROMPT:
      return {
        ...state,
        promptSettings: action.promptSettings,
      };
    case FACEBOOK_ADS_DISCONNECT_DONE:
      return {
        ...state,
        isFacebookConnected: false,
        shouldFacebookPopup: false,
      };
    case GOOGLE_ADS_DISCONNECT_DONE:
      return {
        ...state,
        isGoogleAdsConnected: false,
        shouldGooglePopup: false,
      };
    case TIKTOK_ADS_DISCONNECT_DONE:
      return {
        ...state,
        isTiktokAdsConnected: false,
        shouldTiktokPopup: false,
      };
    case CONNECT_STATUS:
      return {
        ...state,
        connectedStatus: action.payload,
      };
    case FETCH_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export const isFacebookConnected = (state = false, action: any) => {
  switch (action.type) {
    case FACEBOOK_ADS_DISCONNECT_DONE:
      return false;
    case SET_OPTIMISTIC_IMPORTING_FOR_SERVICE:
      return action.serviceId === "facebook-ads" || state;
    default:
      return state;
  }
};

export const isGoogleAdsConnected = (state = false, action: any) => {
  switch (action.type) {
    case GOOGLE_ADS_DISCONNECT_DONE:
      return false;
    case GOOGLE_ADS_CONNECTED:
      return true;
    default:
      return state;
  }
};

export const isTiktokAdsConnected = (state = false, action: any) => {
  switch (action.type) {
    case TIKTOK_ADS_DISCONNECT_DONE:
      return false;
    default:
      return state;
  }
};
