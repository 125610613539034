import { Fragment, useMemo } from "react";
import { LineChart, Line, Tooltip as ChartTooltip } from "recharts";
import { MetricsWithSelector } from "@tw/types/module/metrics/benchmarkMetrics";
import { formatNumber } from "../utils/formatNumber";
import moment from "@tw/moment-cached/module/timezone";
import BaseChart from "./BaseChart/BaseChart";
import React from "react";
import { CustomLineTooltip } from "./CustomLineTooltip";
import { Flex, FormattedColor, Loader } from "@tw/ui-components";
import { Spinner } from "@shopify/polaris";

type TileExpandProps = {
  chartData: any[];
  selectedMetrics: MetricsWithSelector<any>[];
  isFacebookConnected: boolean;
  isGoogleAdsConnected: boolean;
  isTiktokAdsConnected: boolean;
  loading: boolean;
  dateRange: string;
  compareRange: string;
};

export const LineChartTrends: React.FC<TileExpandProps> = (props) => {
  const {
    chartData,
    selectedMetrics,
    isFacebookConnected,
    isGoogleAdsConnected,
    isTiktokAdsConnected,
    loading,
    dateRange,
    compareRange
  } = props;
  const ticksInterval = useMemo(() => {
    return Math.floor(chartData.length / 30);
  }, [chartData]);
  const tickFormatter = (
    value: number,
    index: number,
    metric: MetricsWithSelector<any>
  ) => {
    if (index % 2 !== 0) {
      return "";
    }
    const { format, toFixed, minimumFractionDigits } = metric || {};
    return formatNumber(format === "percent" ? value / 100 : value, {
      style: format || "decimal",
      currency: "USD",
      maximumFractionDigits: toFixed || 2,
      minimumFractionDigits: minimumFractionDigits || 0,
      notation: "compact",
    });
  };

  const showRightAxis = selectedMetrics.length > 1;

  return (
    <div className="white-chart-wrapper">
      {loading && (
        <div>
          <Loader color="one.6">Loader</Loader>{" "}
        </div>
      )}
      <Flex>
        <Flex
          fw="500"
          left={-15}
          c={selectedMetrics[0].color as FormattedColor}
        >
          {selectedMetrics[0].label}
        </Flex>
        <Flex
          fw="500"
          ml="auto"
          c={selectedMetrics[1]?.color as FormattedColor}
        >
          {selectedMetrics[1]?.label}
        </Flex>
      </Flex>
      <div>
        <BaseChart
          data={chartData}
          showTooltip={true}
          ChartType={LineChart}
          margin={
            showRightAxis ? { right: -15, left: -15 } : { right: 30, left: -15 }
          }
          xAxis={[
            {
              interval: ticksInterval,
              tickFormatter: (value: string) => {
                if (!value || !moment(value).isValid()) {
                  return "";
                }
                if (value.includes("T")) {
                  return moment(value).format("HH:mm");
                }
                return moment(value).format("MMM D");
              },
              dataKey: "name",
              fill: "rgb(32, 34, 35)",
            },
          ]}
          yAxis={[
            {
              tickMargin: 30,
              yAxisId: "left",
              tickFormatter: (value, index) => {
                return tickFormatter(value, index, selectedMetrics[0]);
              },
            },
            {
              yAxisId: showRightAxis ? "right" : "left",
              tickMargin: 30,
              orientation: showRightAxis ? "right" : "left",
              tickFormatter: (value, index) => {
                return tickFormatter(value, index, selectedMetrics[1]);
              },
            },
          ]}
        >
          {selectedMetrics &&
            selectedMetrics.map((sel, i) => {
              const isUserData =
                (sel.provider === "google-ads" && isGoogleAdsConnected) ||
                (sel.provider === "facebook-ads" && isFacebookConnected) ||
                (sel.provider === "tiktok-ads" && isTiktokAdsConnected);
              const yAxisIdSide = i === 0 ? "left" : "right";
              return (
                <Fragment key={sel.key}>
                  <Line
                    yAxisId={yAxisIdSide}
                    type="monotone"
                    dataKey={(dataPoint) => {
                      return dataPoint[`${sel.provider}_${sel.key}`!]
                        ? dataPoint[`${sel.provider}_${sel.key}`!]
                        : 0;
                    }}
                    stroke={sel.color}
                    strokeWidth={3}
                    strokeOpacity={1}
                    dot={false}
                    activeDot={{
                      style: {
                        cursor: "pointer",
                        fill: "white",
                        opacity: 1,
                      },
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <Line
                    yAxisId={yAxisIdSide}
                    type="monotone"
                    dataKey={(dataPoint) => {
                      return dataPoint[
                        `${sel.provider}_${sel.key}_past_period`!
                      ]
                        ? dataPoint[`${sel.provider}_${sel.key}_past_period`!]
                        : 0;
                    }}
                    stroke={sel.color}
                    strokeWidth={3}
                    strokeOpacity={0.5}
                    dot={false}
                    activeDot={{
                      style: {
                        cursor: "pointer",
                        fill: "white",
                        opacity: 1,
                      },
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  {isUserData && (
                    <Line
                      yAxisId={yAxisIdSide}
                      type="monotone"
                      dataKey={(dataPoint) => {
                        return dataPoint[`${sel.provider}_${sel.key}_user`!]
                          ? dataPoint[`${sel.provider}_${sel.key}_user`!]
                          : 0;
                      }}
                      stroke={sel.color}
                      strokeDasharray="3 3"
                      strokeWidth={3}
                      strokeOpacity={1}
                      dot={false}
                      activeDot={{
                        style: {
                          cursor: "pointer",
                          fill: "white",
                          opacity: 1,
                        },
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <ChartTooltip
                    content={
                      <CustomLineTooltip
                        data={selectedMetrics}
                        isFacebookConnected={isFacebookConnected}
                        isGoogleAdsConnected={isGoogleAdsConnected}
                        isTiktokAdsConnected={isTiktokAdsConnected}
                        currentDate={dateRange}
                        pastPeriodDate={compareRange}
                      />
                    }
                    cursor={{ fill: "none" }}
                    labelStyle={{ fontWeight: "bold" }}
                    contentStyle={{ fontSize: "10px" }}
                  />
                </Fragment>
              );
            })}
        </BaseChart>
      </div>
    </div>
  );
};
