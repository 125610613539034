import { FC, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch } from "../..";
import { Card, Flex, Button, Text, Loader, Tooltip } from "@tw/ui-components";
import { CircleIcon } from "../CircleIcon";
import { ConnectionDetailsModal } from "./ConnectionDetailsModal";

const IntegrationWidgetButtons: FC<any> = ({
  connected,
  disconnect,
  connect,
  id,
  name,
  configHook,
  loading,
}) => {
  const onClick = connected ? disconnect : connect;
  const className = [].join(" ");
  const text = connected ? "Disconnect" : "Connect";

  return (
    <>
      {connected && configHook && (
        <button
          onClick={configHook}
          id="integrations-disconnect-id"
          aria-label="Disconnect"
          className={className}
        >
          Manage
        </button>
      )}
      <Tooltip
        width={200}
        zIndex={999999}
        multiline
        label={
          connected
            ? undefined
            : `Connect your ${name} account to compare to your personal data`
        }
      >
        <Button onClick={onClick} variant={connected ? "white" : "secondary"}>
          {loading ? <Loader size="xs" /> : text}
        </Button>
      </Tooltip>
    </>
  );
};

export const IntegrationWidget: FC<any> = ({
  id,
  name,
  icon,
  connected,
  connectHook,
  disconnectHook,
  connectedStatus,
  configHook,
}) => {
  const [showConnectionDetails, setShowConnectionDetails] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const connect = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(connectHook());
    } catch (e) {
      toast.error(
        `Something went wrong. Please refresh the page or try again later`
      );
    } finally {
      setLoading(false);
    }
  }, [connectHook, dispatch]);

  return (
    <div>
      <Card shadow="sm" miw={300} mih={72}>
        <Flex gap="lg" justify="space-between">
          <Flex align="center" gap="xs">
            <img src={icon} alt="icon" className="h-5 w-5" />
            <Text weight={400} size="sm">
              {name}
            </Text>
          </Flex>
          <IntegrationWidgetButtons
            id={id}
            name={name}
            connect={connect}
            connected={connected}
            disconnect={disconnectHook}
            loading={loading}
          />
        </Flex>
        {connected ? (
          <div
            onClick={() => setShowConnectionDetails(true)}
            className={`flex gap-2 items-center text-xs cursor-pointer`}
          >
            <CircleIcon
              color={connectedStatus.status !== "error" ? "green" : "red"}
            />
            View Connection Details
          </div>
        ) : (
          <div className={`flex gap-2 items-center text-xs`}>
            <CircleIcon color={"orange"} />
            Connect Your Account
          </div>
        )}
      </Card>
      <ConnectionDetailsModal
        connected={connected}
        platformName={name}
        serviceId={id}
        open={showConnectionDetails}
        onClose={() => setShowConnectionDetails(false)}
        configHook={configHook}
      />
    </div>
  );
};
