import { Button, DatePicker, OptionList, Popover } from "@shopify/polaris";
import { useState, useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { CalendarMinor } from "@shopify/polaris-icons";
import { DatePickerSelection } from "../types/types";

export const TODAY = "Today";
export const YESTERDAY = "Yesterday";

type DatePickerComponentProps = {
  dateRange: { start: moment.Moment; end: moment.Moment };
  compareRange: { start: moment.Moment; end: moment.Moment };
  onApply: (
    range: DatePickerComponentProps["dateRange"],
    compareRange: DatePickerComponentProps["compareRange"]
  ) => void;
};

const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
  dateRange,
  compareRange,
  onApply,
}) => {
  const [selected, setSelected] = useState(dateRange);
  const [selectedDateObject, setSelectedDateObject] = useState({
    start: dateRange.start.toDate(),
    end: dateRange.end.toDate(),
  });

  const [selectedCompared, setSelectedCompared] = useState(dateRange);
  const [selectedDateComparedObject, setSelectedDateComparedObject] = useState({
    start: compareRange.start.toDate(),
    end: compareRange.end.toDate(),
  });

  const [{ comparedMonth, comparedYear }, setComparedDate] = useState({
    comparedMonth: moment().month(),
    comparedYear: moment().year(),
  });

  const [{ month, year }, setDate] = useState({
    month: moment().month(),
    year: moment().year(),
  });
  const [isOpen, setIsOpen] = useState(false);

  const [showCompareSection, setShowCompareSection] = useState(false);
  const [selectedOption, setSelectedOption] = useState("LAST_7_DAYS");

  const compareOptions = useMemo(() => {
    return [
      {
        value: {
          start: moment(selected.start)
            .subtract(moment(selected.end).diff(selected.start, "days"), "days")
            .startOf("day"),
          end: moment(selected.start).subtract(1, "day").endOf("day"),
          id: "LAST_PERIOD",
        },
        label: "Last Period",
        active: true,
      },
    ];
  }, [selected.end, selected.start]);

  const onSelectionChange = useCallback(
    (range: any, setFunc: any, setDateFunc: any, changeompare = false) => {
      setDateFunc(range);
      let { start, end } = range || {};
      start = moment()
        .year(start.getFullYear())
        .month(start.getMonth())
        .date(start.getDate())
        .startOf("day") as any;
      end = moment()
        .year(end.getFullYear())
        .month(end.getMonth())
        .date(end.getDate())
        .endOf("day") as any;

      const newSelected = {
        start,
        end,
      };
      setFunc(newSelected);
      if (changeompare) {
        const prevPeriodOptionId = compareOptions.find((x) => x.active)?.value
          ?.id;
        if (prevPeriodOptionId === "LAST_PERIOD") {
          const newStartCompare = moment(start)
            .subtract(moment(end).diff(start, "days") + 1, "days")
            .startOf("day");
          const newEndCompare = moment(start).subtract(1, "days").endOf("day");
          onSelectionChange(
            { start: newStartCompare.toDate(), end: newEndCompare.toDate() },
            setSelectedCompared,
            setSelectedDateComparedObject
          );
        }
      }
    },
    [compareOptions]
  );
  const onOptionSelect = useCallback(
    (val: any) => {
      const { start, end } = val;
      const prevPeriodOptionId = compareOptions.find((x) => x.active)?.value
        ?.id;
      setSelectedOption(val.id);
      if (prevPeriodOptionId === "LAST_PERIOD") {
        const newStartCompare = moment(start)
          .subtract(moment(end).diff(start, "days") + 1, "days")
          .startOf("day");

        const newEndCompare = moment(start).subtract(1, "days").endOf("day");

        onSelectionChange(
          { start: newStartCompare.toDate(), end: newEndCompare.toDate() },
          setSelectedCompared,
          setSelectedDateComparedObject
        );
      }
      onSelectionChange(
        { start: start.toDate(), end: end.toDate() },
        setSelected,
        setSelectedDateObject
      );
      if (!showCompareSection) {
        onApply({ start, end }, selectedCompared);
        setIsOpen(false);
      }
    },
    [
      compareOptions,
      onApply,
      onSelectionChange,
      selectedCompared,
      showCompareSection,
    ]
  );

  const toggleCompareSection = useCallback(() => {
    setShowCompareSection((show) => !show);
  }, []);

  const getPopoverOpenerButtonTitle = useCallback(
    (selected: DatePickerSelection) => {
      const { start, end } = selected;
      const format = "YYYY/MM/DD";
      if (start.format(format) === end.format(format)) {
        return start.format(format);
      }
      return start.format(format) + " - " + end.format(format);
    },
    []
  );

  const onMonthChange = useCallback((month: number, year: number) => {
    setDate({ month, year });
  }, []);

  const onMontComparedChange = useCallback(
    (comparedMonth: number, comparedYear: number) => {
      setComparedDate({ comparedMonth, comparedYear });
    },
    []
  );

  const options = useMemo(() => {
    return [
      {
        value: {
          start: moment().subtract(7, "days").startOf("day"),
          end: moment().subtract(1, "day").endOf("day"),
          id: "LAST_7_DAYS",
        },
        id: "summary_date_picker_last_7_days",
        label: "Last 7 Days",
        active: selectedOption === "LAST_7_DAYS",
      },
      {
        value: {
          start: moment().subtract(31, "days").startOf("day"),
          end: moment().subtract(1, "day").endOf("day"),
          id: "LAST_30_DAYS",
        },
        id: "summary_date_picker_last_30_days",
        label: "Last 30 Days",
        active: selectedOption === "LAST_30_DAYS",
      },
    ];
  }, [selectedOption]);

  return (
    <Popover
      fluidContent
      active={isOpen}
      activator={
        <div className="flex flex-col">
          <div>
            <Button
              onClick={() => setIsOpen((x) => !x)}
              disclosure
              accessibilityLabel="Date Picker Menu Item"
              icon={CalendarMinor}
            >
              {getPopoverOpenerButtonTitle(selected)}
            </Button>
          </div>
        </div>
      }
      onClose={() => setIsOpen(!isOpen)}
      sectioned
      fullHeight
      preferredAlignment="left"
    >
      <div
        className="flex flex-col sm:flex-row gap-20 justify-between"
        aria-label="Date Selections"
        style={{ maxWidth: "800px" }}
      >
        <div className="flex gap-4 flex-col" style={{ maxWidth: "370px" }}>
          <OptionList
            onChange={(updated) => {
              onOptionSelect(updated[0]);
            }}
            options={options as any}
            selected={[]}
          />
          <DatePicker
            multiMonth={false}
            disableDatesBefore={moment().subtract(90, "days").toDate()}
            month={month}
            year={year}
            allowRange
            onChange={(newVal) =>
              onSelectionChange(
                newVal,
                setSelected,
                setSelectedDateObject,
                true
              )
            }
            onMonthChange={onMonthChange}
            selected={selectedDateObject}
            disableDatesAfter={moment().subtract(1, "days").toDate()}
          />
        </div>
        {showCompareSection && (
          <div
            className="flex gap-4 flex-col flex-auto"
            style={{ maxWidth: "370px" }}
          >
            <p className="font-semibold mb-4">And compare to:</p>
            <OptionList
              onChange={(updated) => {}}
              options={compareOptions as any}
              selected={[]}
            />
            <div>
              <DatePicker
                multiMonth={false}
                month={comparedMonth}
                year={comparedYear}
                disableDatesBefore={moment().subtract(180, "days").toDate()}
                onChange={(newVal) => {
                  onSelectionChange(
                    newVal,
                    setSelectedCompared,
                    setSelectedDateComparedObject
                  );
                }}
                onMonthChange={onMontComparedChange}
                selected={selectedDateComparedObject}
                allowRange
                disableDatesAfter={
                  new Date(
                    moment(selected.end)
                      .subtract(1, "days")
                      .toDate()
                      .toLocaleString("en-US", {
                        timeZone: moment().tz(),
                      })
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        <Button
          primary
          onClick={() => {
            onApply(selected, selectedCompared);
            setIsOpen(false);
          }}
        >
          Apply
        </Button>
        <Button outline onClick={toggleCompareSection}>
          Compared to...
        </Button>
      </div>
    </Popover>
  );
};

export default DatePickerComponent;
