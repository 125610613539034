import { ServicesId } from "../types/types";

export const convertAcountInfo = (serviceId: ServicesId, data: any) => {
    if (serviceId === 'google-ads') {
        return data?.accounts;
    }
    if (serviceId === 'tiktok-ads') {
        return data.map((account: string) => {
            return {
            manager: account,
            descriptive_name: account,
            };
          })
    }
    const accounts = data?.result;
    const facebookTransformedData: any = {};
    for (const accountId in accounts) {
        const account = accounts[accountId];
        facebookTransformedData[account.account_id] = {
            manager: account?.account_id,
            descriptive_name: account.name,
            ...account
        };
    }
    return facebookTransformedData;
}

