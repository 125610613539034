import { AbstractChart } from "@tw/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatNumber } from "../utils/formatNumber";
import { IconProps, Provider } from "../types/types";
import { MetricsWithSelector } from "@tw/types/module/metrics/benchmarkMetrics";
import { ReactComponent as QuestionMark } from "../icons/question-mark.svg";
import { Loading } from "./Loading";
import { Checkbox, Tooltip } from "@shopify/polaris";
import { ReactComponent as Arrow } from "../icons/arrow.svg";
import { ICONS_NAMES, Services } from "../constants/general";
import { RootState, useAppSelector } from "..";
import { Card, Box, Badge, Text, Icon } from "@tw/ui-components";
import { getUserId } from "../utils/axiosInstance";

type TileProps = {
  provider: Provider;
  value: number;
  userValue?: number | string | any;
  chartValue?: AbstractChart[];
  valuePastPeriod: number;
  metric: MetricsWithSelector<any>;
  loading?: boolean;
  color?: string;
  bgColor?: string;
  isActive?: boolean;
  checkboxDisabled: boolean;
  expandTile: (metric: MetricsWithSelector<any>) => void;
};

export const Tile: React.FC<TileProps> = (props) => {
  let {
    provider,
    value,
    valuePastPeriod,
    metric,
    loading,
    isActive,
    checkboxDisabled,
    expandTile,
    userValue,
  } = props;
  const { label, shortLabel, toFixed, format, tip } = metric;  
  const deltaIsPositive: boolean = label?.includes("CPC")
    ? value < valuePastPeriod
    : value > valuePastPeriod;
  const [currentPeriodValue, setCurrentPeriodValue] = useState<number>(0);
  const [currentPeriodValueLength, setCurrentPeriodValueLength] = useState(0);
  const [pastPeriodValue, setPastPeriodValue] = useState<number>(0);
  const [percentageChange, setPercentageChange] = useState<number>(0);
  const { googleAds, facebookAds, tiktokAds } = useAppSelector(
    (state: RootState) => state.accounts
  );
  const accounts = useAppSelector((state: RootState) => state.accounts);

  const fullTip = useMemo(() => {
    if (!tip) return "";
    let ft = tip;
    if (accounts.selectedCategory !== "all") {
      ft = `${ft} for ${accounts.selectedCategory} industry`;
    } else {
      ft = `${ft} for all industries`;
    }
    if (
      accounts.selectedAOV &&
      accounts.selectedAOV !== "all" &&
      accounts.selectedAOV !== ""
    ) {
      ft = `${ft} with AOV ${accounts.selectedAOV}.`;
    }
    if (
      accounts.selectedRevenue &&
      accounts.selectedRevenue !== "all" &&
      accounts.selectedRevenue !== ""
    ) {
      ft = `${ft} with spend ${accounts.selectedRevenue}.`;
    }
    return ft;
  }, [
    accounts.selectedAOV,
    accounts.selectedCategory,
    accounts.selectedRevenue,
    tip,
  ]);

  const handleTileClick = () => {
    expandTile(metric);
  };

  const formatValue = useCallback(
    (v: number) => {
      let currValueLength = formatNumber(v, {
        style: format || "decimal",
        currency: "USD",
        minimumFractionDigits: toFixed || 0,
        maximumFractionDigits: toFixed || 0,
      }).length;

      if (format === "percent") {
        currValueLength -= 3;
      }
      return currValueLength;
    },
    [format, toFixed]
  );

  useEffect(() => {
    let v = value !== undefined ? value : 0;
    let vPastPeriod = valuePastPeriod !== undefined ? valuePastPeriod : 0;
    if (format === "percent") {
      v /= 100;
      vPastPeriod /= 100;
    }
    const currValueLength = formatValue(v);
    setCurrentPeriodValue(v);
    setCurrentPeriodValueLength(currValueLength);
    setPastPeriodValue(vPastPeriod);
  }, [format, value, valuePastPeriod, formatValue]);

  useEffect(() => {
    if (pastPeriodValue !== 0) {
      const percentageChange =
        ((currentPeriodValue - pastPeriodValue) / pastPeriodValue) * 100;
      setPercentageChange(percentageChange);
    }
  }, [pastPeriodValue, currentPeriodValue]);

  const accountName =
    metric.provider === "facebook-ads"
      ? (facebookAds[0] as any)?.name
      : metric.provider === "google-ads"
      ? (Object.values(googleAds)[0] as any)?.descriptive_name
      : metric.provider === "tiktok-ads"
      ? (tiktokAds[0] as any)?.id
      : "Unlock your data";
  const checkBoxTile = () => {
    return (
      <Checkbox
        label="Show In Graph"
        checked={isActive}
        onChange={handleTileClick}
        disabled={checkboxDisabled}
      />
    );
  };
  const UserValue = () => {
    return (
      <Badge radius="md">
        {!isNaN(userValue as number) ? (
          <Text miw={50} align="center">
            {formatNumber(
              format === "percent"
                ? (userValue as number) / 100
                : (userValue as number),
              {
                style: format || "decimal",
                currency: "USD",
                minimumFractionDigits: toFixed || 0,
                maximumFractionDigits: toFixed || 0,
              }
            )}
          </Text>
        ) : (
          <Box miw={50} px="md">
            {userValue}
          </Box>
        )}
      </Badge>
    );
  };
  return (
    <Card shadow="sm" overflow="hidden" miw={352}>
      <Box px="xs" pt="xs">
        {loading && (
          <div className="flex w-full items-center justify-center">
            <Loading />
          </div>
        )}
        {!loading && (
          <>
            <Card.Section mx={0} pb="sm">
              <div className="relative h-full rounded flex flex-col gap-4 group">
                <div>
                  <div className=" w-full flex justify-between items-start">
                    <div className="flex items-center gap-2">
                      <div className="flex flex-nowrap gap-4 items-center">
                        <Icon
                          name={ICONS_NAMES[provider] as IconProps}
                          size={14}
                        />
                        <Text weight={500}>
                          {Services[provider].name} - {shortLabel || label}
                        </Text>
                      </div>
                      <div
                        className={`flex rounded-md arrow-wrapper self-start items-center flex-nowrap gap-2 px-0${
                          deltaIsPositive
                            ? " text-green"
                            : !percentageChange
                            ? " black"
                            : " text-dark-red"
                        }`}
                      >
                        {!!percentageChange && (
                          <Arrow
                            style={{
                              transform:
                                percentageChange > 0 ? "" : "rotate(180deg)",
                            }}
                            width={14}
                            height={14}
                          />
                        )}
                        <span>
                          {formatNumber(percentageChange / 100, {
                            style: "percent",
                            minimumFractionDigits: toFixed ?? 0,
                            maximumFractionDigits: toFixed ?? 2,
                          }).replace("-", "")}
                        </span>
                      </div>
                    </div>
                    {fullTip && fullTip.length > 0 && (
                      <Tooltip content={fullTip}>
                        <div className="group-hover:opacity-100 transition-opacity">
                          {/*opacity-0  I think this style supposed to stay, but Allen doesn't want. חבל :) */}
                          <QuestionMark
                            width={16}
                            height={16}
                            className="text-logo flex cursor-pointer"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className="tile-body flex h-full flex-col cursor-pointer">
                  <div className="h-full flex flex-col gap-4 justify-between">
                    <div className="sm:flex sm:justify-between items-center">
                      <div className="flex flex-col">
                        <p
                          className="font-semibold text-md sm:text-md "
                          style={{ minWidth: `${currentPeriodValueLength}ch` }}
                        >
                          {formatNumber(currentPeriodValue, {
                            style: format || "decimal",
                            currency: "USD",
                            minimumFractionDigits: toFixed || 0,
                            maximumFractionDigits: toFixed || 0,
                          })}
                        </p>
                      </div>
                      {getUserId() && (
                        <div className="flex flex-col dataWrapper">
                          {accountName ? (
                            <Tooltip content={accountName}>
                              <UserValue />
                            </Tooltip>
                          ) : (
                            <UserValue />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </Card.Section>

            <Card.Section mx={0} pt="sm" withBottomBorder={false} withBorder>
              {checkboxDisabled ? (
                <Tooltip
                  content={
                    "You can only compare data from up to 2 benchmarks at a time"
                  }
                >
                  {checkBoxTile()}
                </Tooltip>
              ) : (
                checkBoxTile()
              )}
            </Card.Section>
          </>
        )}
      </Box>
    </Card>
  );
};
