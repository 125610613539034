import { AppProvider } from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import NavigationLink from "./components/NavigationLink";
import { useMemo, useEffect, useState } from "react";
import { Loading } from "./components/Loading";
import { MainFrame } from "./components/MainFrame";
import { useAppSelector } from ".";
import { useAppDispatch } from ".";
import { genericEventLogger, dataLayerInformAuth } from './utils/dataLayer';
import { ThemeProvider } from '@tw/ui-components';
import { firebaseUser } from "./ducks/user"
import { onAuthStateChanged,  } from "firebase/auth";
import { auth } from './config/firebase';

import "@shopify/polaris/build/esm/styles.css";
import "./App.css";

function App() {
  const dispatch = useAppDispatch();
  const { loading, error, user } = useAppSelector((state) => state.user);
  const [showFrame, setShowFrame] = useState(false);

  useEffect(()=>{
    genericEventLogger('Trends', {action: 'page_loaded'}) 
  }, [])

  const element = useMemo(() => {
    if (loading) {
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      );
    }
    if (error.message) {
      genericEventLogger('Trends', { action: 'Login Failed', error: error.message }) 
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>Error: {error.message}</p>
        </div>
      );
    }
      return showFrame && <MainFrame user={user as any} auth={auth} />;

  }, [user, loading, error, showFrame]);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userFirebase) => {
      if (userFirebase) {
        dispatch(firebaseUser(userFirebase))
        dataLayerInformAuth(userFirebase)
        setShowFrame(true)
      } else {
        setShowFrame(true)
      }
    });
    unsubscribe();
  }, [user, dispatch]);

  return <ThemeProvider>
            <AppProvider i18n={enTranslations} linkComponent={NavigationLink}>{element}</AppProvider>
        </ThemeProvider>;
}

export default App;
