import { facebookConnectOnPress, googleAdsConnectOnPress, facebookDisconnect, googleDisconnect, tiktokAdsConnectOnPress, tiktokDisconnect } from '../../ducks/integrations';
import facebookIcon from '../../icons/integ/Facebook.svg';
import googleAdsIcon from '../../icons/integ/Google Ads.svg';
import tiktokIcon from '../../icons/integ/Tik Tok.svg'
import { IntegrationWidget } from './IntegrationWidget';
import DisconnectFromFacebookExplanation from '../DisconnectFromFacebookExplanation';
import DisconnectFromGoogleExplanation from '../DisconnectFromGoogleExplanation';
import { useAppSelector, RootState, useAppDispatch } from '../..';
import { showPrompt, hidePrompt } from '../../ducks/accounts'
import { getUserId } from "../../utils/axiosInstance";
import { FacebookAccountObject } from '../../types/types';



const Integrations = () => {
  const dispatch = useAppDispatch();
  const { isFacebookConnected, isGoogleAdsConnected, isTiktokAdsConnected, connectedStatus, facebookAds, googleAds, tiktokAds } = useAppSelector((state: RootState) => state.accounts);
  const currentShopId = `tw-trends-${getUserId()}`;
  const googleAccountId = googleAds? Object.keys(googleAds)?.[0] : ''; 
  const googleCustomerId = googleAccountId?.split('/')[1];
  const tiktokAccountId = tiktokAds[0] as { id: string };

  const integrationsWidgets = [
    {
      id: 'facebook-ads',
      name: 'Meta',
      icon: facebookIcon,
      serviceId: 'facebook-ads',
      connected: isFacebookConnected,
      connectHook: facebookConnectOnPress,
      disconnectHook: async () =>
      dispatch(
        showPrompt({
          title: 'Disconnect from Facebook?',
          children: DisconnectFromFacebookExplanation,
          primaryAction: {
            content: 'Disconnect',
            onAction: () => {
              dispatch(hidePrompt());
              dispatch(facebookDisconnect(currentShopId, (facebookAds?.[0] as FacebookAccountObject)?.account_id));
            },
          },
        })
      ),
      connectedStatus: connectedStatus['facebook-ads'],
    },
    {
      id: 'google-ads',
      name: 'Google Ads',
      icon: googleAdsIcon,
      connected: isGoogleAdsConnected,
      connectHook: googleAdsConnectOnPress,
      disconnectHook: async () =>
      dispatch(
        showPrompt({
          title: 'Disconnect from Google?',
          children: DisconnectFromGoogleExplanation,
          primaryAction: {
            content: 'Disconnect',
            onAction: () => {
              dispatch(hidePrompt());
              dispatch(googleDisconnect(currentShopId, googleCustomerId));
            },
          },
        })
      ),
      connectedStatus: connectedStatus['google-ads'],
    },
    {
      id: 'tiktok-ads',
      name: 'TikTok',
      icon: tiktokIcon,
      connected: isTiktokAdsConnected,
      connectHook: tiktokAdsConnectOnPress,
      disconnectHook: async () =>
      dispatch(
        showPrompt({
          title: 'Disconnect from Tiktok?',
          children: DisconnectFromGoogleExplanation,
          primaryAction: {
            content: 'Disconnect',
            onAction: () => {
              dispatch(hidePrompt());
              dispatch(tiktokDisconnect(currentShopId, tiktokAccountId.id));
            },
          },
        })
      ),
      connectedStatus: connectedStatus['tiktok-ads'],
    },
  ];

  return (
    <div className="flex gap-6">
      {integrationsWidgets.map((w, i) => (
        <IntegrationWidget key={i} {...w} />
      ))}
    </div>
  );
};

export default Integrations;