import { useCallback, useEffect, useState } from "react";
import { RootState, useAppDispatch } from "..";
import {
  fetchBenchmarksAsync,
  onDateRangeChanged,
  TOGGLE_CHART,
  FILTER_TILES,
} from "../ducks/benchmarks";
import {
  supportedMetrics,
  colorsMetrics,
} from "@tw/constants/module/Metrics/benchmarkMetrics";
import { IconProps } from "../types/types";
import { MetricsWithSelector } from "@tw/types/module/metrics/benchmarkMetrics";
import { Section } from "./Section";
import { DropDown } from "./DropDown";
import {
  AOV_SEGMENTS,
  TOTAL_REVENUE_SEGMENTS,
  ICONS_NAMES,
  Services,
} from "../constants/general";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import {
  Card,
  Collapse,
  Flex,
  Icon,
  Stack,
  Text,
  Button,
} from "@tw/ui-components";
import { useAppSelector } from "..";
import { convertedDataToChart } from "../utils/convertDataToChart";
import { LineChartTrends } from "./LineChartTrends";
import INDUSTRIES from "../constants/industries";
import DatePickerComponent from "./DatePicker";
import moment from "moment";
import IntegrationWidgets from "./Integrations/IntegrationWidgets";
import { BaseLegend } from "./BaseChart/BaseLegend";
import { updateAccountData } from "../ducks/accounts";
import statsSelectors from "@tw/stats";
import { round } from "lodash";
import { Banner, Tooltip } from "@shopify/polaris";
import PopoverButton from "./PopoverButton";
import { getPopoverItems } from "../utils/exportPopoverItems";
import { useQueryParams } from "../utils/useQueryParams";

type BenchmarksProps = {};

export const Benchmarks: React.FC<BenchmarksProps> = () => {
  const dispatch = useAppDispatch();
  const [user] = useAuthState(auth);
  const accounts = useAppSelector((state: RootState) => state.accounts);
  const { dateRange, compareRange, showChart, loading, filterTiles } = useAppSelector(
    (state: RootState) => state.benchmarks
  );
  const { updateQueryParam } = useQueryParams();

  const [shopCount, setShopCount] = useState<number>(0);
  const [warningBanner, setWarningBanner] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any[]>([]);
  const [selectedMetrics, setSelectedMetrics] = useState<
    MetricsWithSelector<any>[]
  >([supportedMetrics["triple-whale"]!["blendedRoas"]!]);

  const [collapsed, setCollapsed] = useState(false);

  const {benchmarks, benchmarksPastPeriod}: {benchmarks: Array<{ id: string }>, benchmarksPastPeriod: Array<{ id: string }>} = useAppSelector(
    (state) => state.benchmarks
  );

  const userMetrics = useAppSelector((state) => state.userMetrics);
  const { isFacebookConnected, isGoogleAdsConnected, isTiktokAdsConnected } =
    useAppSelector((state: RootState) => state.accounts);

  const urlSignFree = `https://app.triplewhale.com/signup-free?lead=trends&uid=${user?.uid}`;

  const onApplyDateRange = (
    range: { start: moment.Moment; end: moment.Moment },
    compRange?: { start: moment.Moment; end: moment.Moment }
  ) => {
    dispatch(onDateRangeChanged(range, compRange));
  };

  const updateFilterTiles = useCallback(
    (service: string) => {
      if(service === 'All') {
        return dispatch({
          type: FILTER_TILES,
          payload: Object.keys(Services),
        });
      }
      const isServiceSelected = filterTiles.includes(service);
      if (isServiceSelected) {
        dispatch({
          type: FILTER_TILES,
          payload: [...filterTiles.filter((s) => s !== service)],
        });
      } else {
        dispatch({ type: FILTER_TILES, payload: [...filterTiles, service] });
      }
    },
    [filterTiles, dispatch]
  );

  const fetch = useCallback(async () => {
    const u = await user?.getIdToken();

    dispatch(
      fetchBenchmarksAsync(
        {
          category: accounts.selectedCategory || undefined,
          aov_segment: accounts.selectedAOV || undefined,
          revenue_segment: accounts.selectedRevenue || undefined,
          start: dateRange.start.startOf("day").format("YYYY-MM-DD"),
          end: dateRange.end.endOf("day").format("YYYY-MM-DD"),
          user: u,
          compare_end: compareRange.end.endOf("day").format("YYYY-MM-DD"),
          compare_start: compareRange.start
            .startOf("day")
            .format("YYYY-MM-DD"),
        } as any,
        "day"
      )
    );
  }, [
    accounts.selectedCategory,
    accounts.selectedAOV,
    accounts.selectedRevenue,
    user,
    dispatch,
    dateRange.start,
    dateRange.end,
    compareRange.end,
    compareRange.start,
  ]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const expandTile = useCallback(
    (metric: MetricsWithSelector<any>) => {
      const isMetricSelected = selectedMetrics.includes(metric);
      if (isMetricSelected) {
        setSelectedMetrics((prevSelectedMetrics) =>
          prevSelectedMetrics.filter((m) => m !== metric)
        );
      } else {
        const availableColors = colorsMetrics.filter(
          (color) =>
            !selectedMetrics.some(
              (selectedMetric) => selectedMetric.color === color
            )
        );
        metric.color = availableColors[0];
        setSelectedMetrics((prevSelectedMetrics) => [
          ...prevSelectedMetrics,
          metric,
        ]);
        dispatch({ type: TOGGLE_CHART, payload: true });
      }
    },
    [selectedMetrics, dispatch]
  );

  useEffect(() => {
    //TODO charts
    const chartData = convertedDataToChart(
      benchmarks,
      benchmarksPastPeriod,
      userMetrics,
      selectedMetrics
    );    
    setChartData(chartData);
    const shopCount = statsSelectors["totalShopCount"]({
      newStats: { benchmarks },
    });
    shopCount > 0 && setShopCount(round(shopCount));
    setWarningBanner(shopCount < 50);
  }, [selectedMetrics, benchmarks, userMetrics]);

  return (
    <div className="pb-[20px]">
      <Flex gap="xl" direction="column">
        {user && (
          <div>
            <Collapse in={collapsed}>
              <Stack spacing="sm">
                <div className="mb-10">
                  {" "}
                  <IntegrationWidgets />
                </div>
              </Stack>
            </Collapse>
            <div
              className="cursor-pointer"
              onClick={() => setCollapsed(!collapsed)}
            >
              <Flex gap="sm" pr="sm">
                <Icon
                  color="one.6"
                  name={collapsed ? "caret-up-bold" : "caret-down"}
                  size={15}
                />
                <Text as="p" color="one.6" lh={1}>
                  {collapsed
                    ? "Collapse Integrations"
                    : "See your Integrations"}
                </Text>
              </Flex>
            </div>
          </div>
        )}
        {warningBanner && (
          <Banner status="warning">
            {shopCount < 10 ? (
              <p>
                Note: We don't have quite enough data based on these filters.
                Please try expanding your search.
              </p>
            ) : (
              <p>
                Note: We recommend expanding your search to include more brands
                in the dataset.
              </p>
            )}
          </Banner>
        )}
        <Flex justify="space-between" direction="column">
          <Flex gap="sm">
            <DropDown
              label="Industry: "
              options={Object.values(INDUSTRIES).map((industry) => ({
                label: industry.label,
                value: industry.id,
              }))}
              onChange={(val) => {
                updateQueryParam("industry", val);
                setShopCount(0);
                dispatch(updateAccountData({ selectedCategory: val }));
              }}
              value={accounts.selectedCategory}
            />
            <DropDown
              label="AOV: "
              options={[{ label: "All", value: "" as any }].concat(
                AOV_SEGMENTS as any
              )}
              onChange={(val) => {
                updateQueryParam("aov", val);
                setShopCount(0);
                dispatch(updateAccountData({ selectedAOV: val }));
              }}
              value={accounts.selectedAOV}
            />
            <DropDown
              label="Annual Revenue: "
              options={[{ label: "All", value: "" as any }].concat(
                TOTAL_REVENUE_SEGMENTS as any
              )}
              onChange={(val) => {
                updateQueryParam("revenue", val);
                setShopCount(0);
                dispatch(updateAccountData({ selectedRevenue: val }));
              }}
              value={accounts.selectedRevenue}
            />
            <DatePickerComponent
              onApply={onApplyDateRange}
              dateRange={dateRange}
              compareRange={compareRange}
            />
            <PopoverButton
              items={getPopoverItems(filterTiles, updateFilterTiles)}
              title="All"
            ></PopoverButton>
            <Tooltip
              content={
                selectedMetrics.length > 0
                  ? showChart
                    ? "Hide Graph"
                    : "Show Graph"
                  : "select a metric"
              }
            >
              <Button
                variant="activator"
                onClick={() => {
                  dispatch({ type: TOGGLE_CHART, payload: !showChart });
                }}
              >
                <Icon
                  name="line-chart"
                  size={20}
                  color={showChart ? "gray.5" : "gray.6"}
                />
              </Button>
            </Tooltip>

            {shopCount > 0 && <Text lh={3}>Based on {shopCount} Stores</Text>}
            {user && (
              <Button
                ml="auto"
                onClick={() => window.open(urlSignFree, "_blank")}
                variant="primary"
              >
                Unlock Your Data
              </Button>
            )}
          </Flex>
        </Flex>
        {selectedMetrics.length > 0 && showChart && (
          <Card shadow="sm">
            <LineChartTrends
              chartData={chartData}
              selectedMetrics={selectedMetrics}
              isFacebookConnected={isFacebookConnected}
              isGoogleAdsConnected={isGoogleAdsConnected}
              isTiktokAdsConnected={isTiktokAdsConnected}
              loading={loading}
              dateRange={`${dateRange.start.startOf("day").format('MMM D')} - ${dateRange.end.format('MMM D, YYYY')}`}
              compareRange={`${compareRange.start.startOf("day").format('MMM D')} - ${compareRange.end.format('MMM D, YYYY')}`}
            />
            <Card shadow="0" mt={20}>
              <div className="flex flex-wrap gap-5 p-6.5">
                {selectedMetrics?.map((metric) => {
                  const providerIconName = metric.provider;
                  return (
                    <BaseLegend
                      key={`indicate_${metric.key}_${metric.provider}`}
                      color={metric.color!}
                      label={metric.shortLabel}
                      active={selectedMetrics.includes(metric)}
                      labelRightIcon={
                        providerIconName ? (
                          <Icon
                            name={ICONS_NAMES[metric.provider] as IconProps}
                            size={14}
                          />
                        ) : undefined
                      }
                      onClick={() => expandTile(metric)}
                      onRemove={() => expandTile(metric)}
                    />
                  );
                })}
              </div>
            </Card>
          </Card>
        )}
        <Flex gap="sm" direction="column" mt="md">
          {Object.entries(supportedMetrics).map(([provider]) => {
              return filterTiles.includes(provider) ? (
                <div
                  key={`div_${provider}`}
                  className="flex flex-wrap items-center justify-between gap-4"
                  style={{
                    filter: shopCount < 10 && shopCount > 0 ? "blur(5px)" : "",
                  }}
                >
                  <Section
                    key={provider}
                    provider={provider as any}
                    selectedMetrics={selectedMetrics}
                    expandTile={expandTile}
                  />
                </div>
              ) : (
                ""
              );
          })}
        </Flex>
      </Flex>
    </div>
  );
};
