import { Dispatch } from "react";
import axiosInstance, { getUserId } from "../utils/axiosInstance";
import { toast } from "react-toastify";
import { reportToHubspot } from "./user";
import { ServicesId } from "../types/types";
import { FACEBOOK_ADS_DISCONNECT_DONE ,GOOGLE_ADS_DISCONNECT_DONE , TIKTOK_ADS_DISCONNECT_DONE } from "./accounts"

export const facebookConnectOnPress = () => {
  try {
    const userId = getUserId();
    const url = `/facebook-ads/login/get-url-trends?userId=${userId}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    toast.error("Error while connecting to Facebook");
  }
};

export const googleAdsConnectOnPress = () => {
  try {
    const userId = getUserId();
    const url = `/google-ads/login/get-url-trends?userId=${userId}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    toast.error("Error while connecting to Google-Ads");
  }
};

export const tiktokAdsConnectOnPress = () => {
  try {
    const userId = getUserId();
    const url = `/tiktok-ads/login/get-url-trends?userId=${userId}`;
    axiosInstance
      .get(url)
      .then((res) => {
        window.location.replace(res.data?.url);
      })
      .catch((e) => {
        throw e;
      });
  } catch (e) {
    toast.error("Error while connecting to Google-Ads");
  }
};

export const setServiceAccountInfo = async (
  shopId: string,
  serviceId: ServicesId,
  accountInfo: any
) => {
  try {
    const url = `${serviceId}/set-account-info`;
    reportToHubspot({ data: { service: serviceId, event: "integration-connected" } })
    return await axiosInstance.post(url, { shopId, accountInfo });
  } catch (e) {
    console.error(e);
  }
};

export function facebookDisconnectDone() {
  return {
    type: FACEBOOK_ADS_DISCONNECT_DONE,
  };
}

export function googleDisconnectDone() {
  return {
    type: GOOGLE_ADS_DISCONNECT_DONE,
  };
}

export function tiktokDisconnectDone() {
  return {
    type: TIKTOK_ADS_DISCONNECT_DONE,
  };
}

export function facebookDisconnect(shopId: string, accountId: string) {
  return async (dispatch: Dispatch<any>) => {
    const url = `/facebook-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: shopId,
        accountId: `act_${accountId}`
      })
      .then(() => {
        dispatch({
          type: "FETCH_USER_METRICS",
          payload: {service_id : 'facebook-ads', metrics: []}
        });
        dispatch(facebookDisconnectDone());

      });
  };
}

export function googleDisconnect(shopId: string, accountId: string) {
  return async (dispatch: Dispatch<any>) => {
    const url = `/google-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: shopId,
        accountId
      })
      .then(() => {
        dispatch({
          type: "FETCH_USER_METRICS",
          payload: {service_id : 'google-ads', metrics: []}
        });
        dispatch(googleDisconnectDone());
      });
  };
}

export function tiktokDisconnect(shopId: string, accountId: string) {
  return async (dispatch: Dispatch<any>) => {
    const url = `/tiktok-ads/login/remove-account`;
    return axiosInstance
      .post(url, {
        shopDomain: shopId,
        accountId
      })
      .then(() => {
        dispatch({
          type: "FETCH_USER_METRICS",
          payload: {service_id : 'tiktok-ads', metrics: []}
        });
        dispatch(tiktokDisconnectDone());
      });
  };
}

