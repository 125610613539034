import { useGoogleLogin } from "@react-oauth/google";
import { Button, Loader } from "@tw/ui-components";
import { signInUpWithGoogle } from "../../ducks/auth";
import { genericEventLogger } from "../../utils/dataLayer";
import { useState } from "react";
import { toast } from "react-toastify";
import { User } from "firebase/auth";
import GoogleIconBase64 from "../../icons/GoogleIconBase64";

type LoginWithGoogleButtonProps = {
  onLoginSuccess?: (user: User) => void;
  signIn?: boolean;
  comparisonEmail?: string;
  transparentButton?: boolean;
};

export const LoginWithGoogleButton: React.FC<LoginWithGoogleButtonProps> = ({
  onLoginSuccess,
  signIn,
  comparisonEmail,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);
      try {
        const user = await signInUpWithGoogle(
          codeResponse.access_token,
          signIn,
          comparisonEmail
        );
        if (onLoginSuccess) {
          await onLoginSuccess(user);
        }
      } catch (error) {
        toast.error((error as any).message);
        genericEventLogger("Trends", {
          action: "Login Failed",
          error: (error as any).message,
        });
      }
      setLoading(false);
    },
    onError: (error) => {
      genericEventLogger("Trends", {
        action: "Login Failed",
        error: (error as any).message,
      });
      toast.error(error.error_description);
    },
    scope: "openid email profile",
  });

  return (
    <div
      className="flex flex-row border border-solid border-gray-400 rounded-md h-10 center-text-in-div cursor-pointer bg-primary text-white justify-center "
      onClick={() => (!loading ? login() : null)}
      id={`${signIn ? "signIn" : "signUp"}-with-google-button`}
      style={{ display: "flex", flexDirection: "row" }}
    >
      <Button
        fw="normal"
        color="one.6"
        radius="default"
        variant="primary"
        leftIcon={loading? "loader-1" : "google"}
      >
        Connect Account
      </Button>
    </div>
  );
};

