import { Checkbox, Container, Flex, FormattedColor, Group, Text, Tooltip } from '@tw/ui-components';
import { CSSProperties, FC, useMemo } from 'react';

type LegendProps = {
  label?: string;
  color: string;
  active?: boolean;
  tooltipText?: string;
  content?: React.ReactNode;
  onClick?: () => void;
  onRemove?: () => void;
  circle?: boolean;
  labelRightIcon?: React.ReactNode;
  labelMaxWidth?: number;
};

export const BaseLegend: FC<LegendProps> = ({
  label,
  color,
  tooltipText,
  content,
  active = true,
  onClick,
  onRemove,
  circle,
  labelRightIcon,
  labelMaxWidth,
}) => {
  const textElement = (
    <Group spacing="xs" noWrap>
      {onRemove && <Checkbox checked onChange={onRemove} />}
      {labelRightIcon}
      <Text size="xs" transform="capitalize" truncate maw={labelMaxWidth}>
        {label}
      </Text>
    </Group>
  );
  const contentStyle: CSSProperties = useMemo(
    () => (content ? { width: 50, height: 50, backgroundColor: 'white' } : { padding: '5px 10px' }),
    [content]
  );

  return (
    <Tooltip label={<Text c={color as FormattedColor}>{tooltipText || label}</Text>} color="white">
      <Flex direction="column" gap="xs" align="center">
        {content && textElement}
        <Group
          align="center"
          position="center"
          style={{
            outline: `1px solid ${color}`,
            borderRadius: circle ? '100%' : 8,
            ...contentStyle,
          }}
          onClick={() => onClick && onClick()}
        >
          {content ?? textElement}
        </Group>
        <Container h={3} w="70%" p={0} style={{ backgroundColor: color, borderRadius: 8 }} />
      </Flex>
    </Tooltip>
  );
};
