import axios from 'axios';
import { baseURL } from '../config';
import { ApiErrorResponse } from '@tw/types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 200000,
});

export function getUserId() {
  return firebase.auth()?.currentUser?.uid;
}

axiosInstance.interceptors.request.use(
  async (config) => {
    let originalRequest = config;
    const token = await firebase.auth().currentUser?.getIdToken()!;
    if (token) {
      originalRequest.headers['Authorization'] = 'Bearer ' + token;
    }
    originalRequest.headers['user'] = getUserId() || '';
    originalRequest.headers['x-tw-shop-id'] = new URLSearchParams(originalRequest.url?.split('?')[1]).get("shop") || `tw-trends-${getUserId()}`;
    return await Promise.resolve(originalRequest);
  },
  (err) => {    
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (e: { response: ApiErrorResponse }) => {
    if (e.response) {
      throw e.response;
    } else {
      throw e;
    }
  }
);



export default axiosInstance;
