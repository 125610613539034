import { supportedMetrics } from "@tw/constants/module/Metrics/benchmarkMetrics";
import {
  Provider,
  MetricSelector,
  MetricsWithSelector,
} from "@tw/types/module/metrics/benchmarkMetrics";
import statsSelectors from "@tw/stats";
import { Tile } from "./Tile";
import { useAppDispatch, useAppSelector } from "..";

import { Tooltip } from "@shopify/polaris";
import { showUpgradeModal } from "../ducks/upgrade";
import { Icon } from "@tw/ui-components";
import { AbstractChart } from "@tw/types/module/types";

type SectionProps = {
  provider: Provider;
  selectedMetrics: MetricsWithSelector<any>[];
  expandTile: (metric: MetricsWithSelector<any>) => void;
};

export const Section: React.FC<SectionProps> = ({
  provider,
  selectedMetrics,
  expandTile,
}) => {
  const { benchmarks, benchmarksPastPeriod, loading } = useAppSelector(
    (state) => state.benchmarks
  );
  const userMetrics = useAppSelector((state) => state.userMetrics);
  const dispatch = useAppDispatch();

  return (
    <>
      {Object.values(supportedMetrics[provider]!).map((metric) => {
        const tileIsActive = selectedMetrics.includes(metric);
        const userValue = !!metric.userSelector ? (
          statsSelectors[metric.userSelector as MetricSelector]({
            newStats: { ...userMetrics },
          })
        ) : (
          <div
            onClick={() => {
              dispatch(showUpgradeModal());
            }}
          >
            <Icon color="one.7" name="lock" size={15} />
          </div>
        );
        const tile = (
          <Tile
            loading={loading}
            provider={provider}
            metric={metric}
            value={statsSelectors[metric.selector]({
              newStats: { benchmarks },
            })}
            userValue={userValue}
            valuePastPeriod={statsSelectors[metric.selector]({
              newStats: { benchmarks: benchmarksPastPeriod },
            })}
            chartValue={statsSelectors[metric.chart]({
              newStats: { benchmarks },
            }) as AbstractChart[]}
            isActive={tileIsActive}
            checkboxDisabled={selectedMetrics?.length > 1 && !tileIsActive}
            expandTile={expandTile}
          />
        );
        return (
          <div key={`${provider}_${metric.key}`} className="flex-auto">
            {tile}
          </div>
        );
      })}
    </>
  );
};
